/* Dropzone */
.dropzone {
  min-height: 207px;
  background: var(--#{$prefix}custom-white) !important;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
  > .dropzone.dz-wrapper {
    border: none !important;
  }
}

dropzone > .dropzone.dz-wrapper.dz-single .dz-preview .dz-image {
  height: 207px !important;
}

.add-store-page-content {
  width: 85%;
  margin: 0 auto;
  padding: 0 3rem;
  & .photo-cover {
    width: 100%;
    height: 405px;
    border-radius: 19px;
    padding: 0;
    overflow: hidden;
    border: solid 1px #707070;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .image-logo {
    position: relative;
    margin-left: 1rem;
    bottom: 0;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
    transform: translateY(-50%);
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

dropzone > .dropzone.dz-wrapper .dz-preview .dz-details {
  display: none;
}
dropzone > .dropzone.dz-wrapper.dz-single .dz-preview .dz-image img {
  object-fit: cover;
  height: 100% !important;
}
.dropzone .dz-preview .dz-remove {
  background-color: $primary-500;
  padding: 1rem;
  color: white;
  font-size: large;
}
// .dropzone .dz-preview .dz-remove{
//   position: absolute;
//   top: 0%;
//   right: 0%;
//   z-index: 10;
//   background-color: rgba(255, 255, 255, 0.8);
//   border-radius: 50%;
//   width: 3rem;
//   height: 3rem;
// }
.agreement-container {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  min-height: auto;
  padding: 2rem 4rem;
  overflow-y: auto;
  & .print {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    display: flex;
    gap: 1.5rem;
  }
}
