.landing-page {

  @media (min-width: 1501px) {
    .video-img2 {
      width: 55%;
    }

    .video-img2 img {
      width: 89%;
    }

    .video-img2 .popup-video img {
      width: auto;
    }

    .nav-sign-in{
      display: none !important;
    }
  }

  /* Normal desktop :1200px. */
  @media (min-width: 1200px) and (max-width: 1500px) {
    .chosse-img {
      width: 570px;
    }

    .nav-sign-in{
      display: none !important;
    }

    #features {
      padding-top: 60px;
    }

    .video-wrap h2 {
      margin-top: 0;
    }

    .video-img2 {
      width: 55%;
    }

    .video-img2 img {
      width: 89%;
    }

    .video-img2 .popup-video img {
      width: auto;
    }

    .chosse-img2 {
      left: 100px;
      width: 40%;
    }

    .ss-eight {
      top: 70px !important;
      left: 40% !important;
    }

    #testimonios {
      background-size: 50%;
    }

    .container-p {
      padding: 0 30px;
    }

    .header-btn {
      margin-left: 20px;
    }

    .slider-active .slick-arrow {
      left: 20px;
    }

    .slider-active .slick-next {
      right: 20px;
      left: auto;
    }

    .section-t h2 {
      font-size: 250px;
    }

    .f-cta-area {
      margin-left: 60px;
      margin-right: 60px;
      padding-left: 50px;
      padding-right: 50px;
    }

    .main-menu ul li:first-child {
      margin-left: 0;
    }

    .header-social.display-ib {
      display: none;
    }

    .wp-img {
      margin-right: 0;
    }

    .contact-img {
      right: 0px;
    }

  }


  /* Normal desktop :992px. */
  @media (min-width: 992px) and (max-width: 1200px) {
    .nav-buttons {
      justify-content: end;
      margin-top: -25px;
      padding-right: 93px;
    }

    .nav-sign-in{
      display: none !important;
    }

    .s-img {
      margin-left: 60px;
      width: 50%;
      margin-bottom: 30px;
    }

    .second-slider-content h2 {
      font-size: 58px;

    }

    .second-slider-content {
      padding-top: 200px;
      padding-bottom: 5px;
    }

    .slider-bg2 {
      min-height: 530px;
    }

    .footer-bg {
      background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box !important;
    }

    .video-wrap h2 {
      margin-top: 0;
    }

    .video-img2 {
      width: 100%;
      position: relative;
      margin-bottom: 80px;
      margin-top: -100px;
    }

    .video-img2 img {
      width: 80%;
    }

    .video-img2 .popup-video img {
      width: auto;
    }

    .ss-eight {
      width: 44%;
      top: inherit !important;
      bottom: 0;
    }

    .ss-eight img {
      width: 100%;
    }

    .chosse-img {
      width: 738px;
    }

    .chosse-img2 {
      left: 0;
      width: 50%;
      position: relative;
      top: -2%;
      text-align: right;
      margin-bottom: 50px;
    }

    #testimonios {
      position: relative;
      background-size: 60%;
      background-position: right 60% !important;
      padding-bottom: 50px !important;
    }

    .footer-social a {
      margin-right: 9px;
    }

    .pricing-body {
      padding: 0px 15px 35px;
    }

    .container-p {
      padding: 0 30px;
    }

    .header-cta ul li {
      margin-left: 20px;
    }

    .section-t h2 {
      font-size: 200px;
    }

    .about-img {
      margin-left: -105px;
    }

    .features-content p {
      padding: 0;
    }

    .services-content {
      padding: 50px 30px;
      padding-bottom: 45px;
    }

    .services-content h4 {
      font-size: 20px;
    }

    .services-content span {
      margin-bottom: 45px;
    }

    .video-wrap img {
      width: 100%;
    }

    .video-position {
      bottom: -222px;
      left: 20px;
      right: 20px;
    }

    .v-padding {
      padding-top: 0;
    }

    .wp-list {
      padding: 35px 15px;
      padding-bottom: 25px;
    }

    .wp-img {
      margin-right: -100px;
    }

    .wp-tag {
      left: 10px;
    }

    .team-info {
      margin-left: 0;
      margin-right: 0;
    }

    .team-info h4 {
      font-size: 18px;
    }

    .single-counter p {
      font-size: 18px;
    }

    .ta-bg {
      margin-left: -25px;
    }

    .b-meta.mb-20 {
      margin-bottom: 10px;
    }

    .b-meta ul li {
      margin-right: 0;
    }

    .blog-content {
      padding: 30px 20px;
    }

    .blog-content h4 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .blog-content p {
      margin-bottom: 20px;
    }

    .f-cta-area {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 50px;
      padding-right: 50px;
    }

    .footer-social span {
      margin-right: 12px;
    }

    .f-insta ul li {
      width: 28%;
    }

    .f-insta ul li a img {
      width: 100%;
    }

    .wp-bg {
      overflow: hidden;
    }

    .s-about-img {
      margin-left: -125px;
    }

    .about-p {
      padding-top: 110px;
    }

    .s-single-services {
      padding: 50px 30px;
    }

    .fshape-one {
      top: 30%;
    }

    .fshape-two {
      left: 9%;
    }

    .choose-wrap.pl-100 {
      padding-left: 50px;
    }

    .choose-content p {
      padding-right: 50px;
    }

    .inner-wp-icon {
      display: none;
    }

    .wp-thumb.mb-35 {
      margin-bottom: 25px;
    }

    .contact-img {
      right: -150px;
    }

    .bsingle__content h2 {
      padding-right: 0;
      font-size: 27px;
    }

    .widget__banner-overly>span {
      margin-bottom: 120px;
    }

    .widget__banner-overly h3 {
      margin-bottom: 91px;
      font-size: 35px;
    }

    .widget__post-content h6 {
      font-size: 14px;
      padding-right: 0;
    }

    .details__content h2 {
      font-size: 26px;
    }

    .related-post-wrap .rp__content {
      padding: 23px;
    }

    .related-post-wrap .rp__content h3 {
      font-size: 20px;
    }

  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .s-img {
      margin-left: 100px;
      margin-top: 0;
      margin-bottom: 40px;
      width: 50%;
    }

    .nav-sign-in{
      display: block !important;
    }

    .nav-buttons {
      display: none !important;
    }

    .footer-bg {
      background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box !important;
    }

    .video-wrap h2 {
      margin-top: 0;
    }

    .video-img2 {
      width: 100%;
      position: relative;
      margin-bottom: 80px;
      margin-top: -100px;
    }

    .video-img2 img {
      width: 80%;
    }

    .video-img2 .popup-video img {
      width: auto;
    }

    .ss-eight {
      width: 44%;
      top: inherit !important;
      bottom: 0;
    }

    .ss-eight img {
      width: 100%;
    }

    .chosse-img2 {
      left: 100px;
      position: relative;
      top: 3%;
      width: 25%;
    }

    .chosse-img2 img {
      width: 100%;
    }

    #testimonios {
      position: relative;
      background-size: 45%;
      background-position: right 90% !important;
      padding-bottom: 50px !important;
    }

    .contact-img2 {
      margin-left: 0;
    }

    .container-p {
      padding: 0 30px;
    }

    .slider-content h2 {
      font-size: 70px;
    }

    .slider-bg {
      min-height: 700px;
    }

    .section-t h2 {
      font-size: 150px;
    }

    .about-img {
      margin-left: 0;
      text-align: center;
      margin-bottom: 30px;
    }

    .about-content.pl-30 {
      padding-left: 0;
      padding-bottom: 4px;
    }

    .features-content p {
      padding: 0;
    }

    .section-title h2 {
      font-size: 52px;
    }

    .services-content {
      padding: 50px 28px;
      padding-bottom: 45px;
    }

    .video-position {
      bottom: 70px;
      left: 120px;
    }

    .wp-img {
      margin-top: 30px;
      margin-bottom: -15px;
      margin-right: 0;
    }

    .counter small {
      top: -20px;
    }

    .cta-right {
      margin-top: 35px;
    }

    .ta-bg {
      background-position: center;
      margin: 25px auto;
    }

    .testimonial-area.pb-120 {
      padding-bottom: 185px;
    }

    .f-cta-area {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 50px;
      padding-right: 50px;
    }

    .menu-area {
      padding: 20px 0;
    }

    .responsive {
      float: right;
      display: block;
      cursor: pointer;
      margin-top: -38px;
    }

    .responsive i {
      color: #fff;
      font-size: 25px;
    }

    .main-menu .has-sub>ul {
      position: relative;
    }

    #mobile-menu {
      display: none;
      background: #fff;
      padding: 20px;
    }

    .main-menu {
      text-align: left !important;
    }

    .main-menu ul li {
      display: block;
      border-bottom: 1px solid #f7f7f7;
      margin-left: 0;
    }

    .main-menu ul li.active a,
    .main-menu ul li:hover a {
      color: #ff3494;
    }

    .main-menu ul>li>a::before {
      content: none;
    }

    .main-menu ul li a {
      color: #707692;
      padding: 12px 20px;
      display: block;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }

    .second-menu {
      margin-bottom: 0;
      box-shadow: none;
      padding: 0;
      margin-top: 0;
    }

    .second-menu::before {
      content: none;
    }

    .second-header {
      padding: 10px 0;
    }

    .second-menu .main-menu ul li {
      margin-left: 0;
    }

    .s-about-img {
      margin-bottom: 20px;
    }

    .s-about-img img {
      width: 100%;
    }

    .s-video-content {
      padding-left: 100px;
    }


    .contact-img {
      display: none;
    }

    .contact-bg.pb-80 {
      padding-bottom: 120px;
    }

    .single-post {
      box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
    }

    .breadcrumb-area {
      min-height: 500px;
    }

    .bsingle__content h2 {
      font-size: 28px;
    }

    .widget__banner-overly>span {
      margin-bottom: 320px;
    }

    .widget__banner-overly h3 {
      margin-bottom: 352px;
      font-size: 80px;
    }

    .widget__banner-overly h3 span {
      font-size: 61px;
    }

    .details__content h2 {
      font-size: 29px;
    }

    .avatar__wrap {
      padding: 50px 65px;
    }

    .b-details-p.pt-120 {
      padding-top: 115px;
    }

    .portfolio .col3 .grid-item,
    .portfolio .col2 .grid-item {
      width: 33.33%;
      padding: 15px;
    }

    .gallery-image img {
      width: 100%;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .nav-buttons {
      display: none !important;
    }

    .nav-sign-in{
      display: block !important;
    }

    .faq-area .section-title {
      margin-top: 80px;
    }

    .choose-btn {
      float: left;
    }

    .choose-btn a {
      margin-bottom: 30px;
    }

    .footer-bg {
      background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box !important;
    }

    .video-wrap h2 {
      margin-top: 0;
    }

    .video-img2 {
      width: 100%;
      position: relative;
      margin-bottom: 80px;
      margin-top: -100px;
    }

    .video-img2 img {
      width: 80%;
    }

    .video-img2 .popup-video img {
      width: auto;
    }

    .ss-eight {
      width: 55%;
      top: inherit !important;
      bottom: 0;
      left: 30% !important;
    }

    .ss-eight img {
      width: 100%;
    }

    .chosse-img {
      width: 70%;
      top: 0;
    }

    .chosse-img2 {
      left: 50px;
      width: 30%;
      position: relative;
      top: -2%;
      text-align: right;
      margin-bottom: 50px;
    }

    .chosse-img2 img {
      width: 100%;
    }

    #testimonios {
      position: relative;
      background-size: 40%;
      background-position: right 80% !important;
      padding-bottom: 50px !important;
      padding-top: 0px !important;
    }

    .footer-social a {
      margin-right: 9px;
    }

    .app-work-content li {
      padding-left: 0;
    }

    .app-work-content {
      background: none;
    }

    .app-work-content li img {
      width: 60px;
    }

    .video-wrap {
      padding-left: 0;
    }

    .newslater button {
      padding: 15px 30px;
    }

    .contact-img2 img {
      width: 100%;
    }

    .contact-img2 {
      margin-left: 0;
      margin-bottom: 50px;
    }

    .slider-bg2 .slider-content>span {
      font-size: 18px;
    }

    .slider-bg2 .video-i {
      display: none;
    }

    .slider-btn {
      display: none;
    }

    .slider-bg2 {
      min-height: 700px;
      position: relative;
      margin-top: -118px;
      z-index: 1;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .portfolio .col3 .grid-item,
    .portfolio .col2 .grid-item {
      width: 100%;
      padding: 15px;
    }

    .gallery-image img {
      width: 100%;
    }

    .pricing-box {
      padding: 0;
    }

    .woocommerce #content div.product .woocommerce-tabs ul.tabs li,
    .woocommerce div.product .woocommerce-tabs ul.tabs li,
    .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li,
    .woocommerce-page div.product .woocommerce-tabs ul.tabs li {
      width: 100%;
      text-align: center;
    }

    .woocommerce ul.products[class*="columns-"] li.product,
    .woocommerce-page ul.products[class*="columns-"] li.product {
      width: 100%;

    }

    .woocommerce #content div.product div.images,
    .woocommerce div.product div.images,
    .woocommerce-page #content div.product div.images,
    .woocommerce-page div.product div.images {
      float: left;
      width: 100%;
      overflow: hidden;
    }

    .has-mega-menu {
      position: relative;
    }

    li.has-sub.has-mega-menu ul {
      position: relative;
      top: 0px;
      /*insert the needed value*/
      z-index: 100;
      left: 0px;
      right: 0px;
      width: 100%;
    }

    li.has-sub.has-mega-menu:hover>ul {
      /*flexbox fallback for browsers that do not support CSS GRID lyout*/
      display: flex;
      flex-wrap: wrap;
      /*CSS GRID lyout*/
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      margin-left: 0px;
      height: 200px;
      overflow: auto;
    }

    .timeline {

      padding: 50px 0px 50px 50px;

    }

    .tag-template img {
      width: 100% !important;
    }

    .quote-post .meta-info {
      padding: 10px 25px;
    }

    .admin-bar .menu-area {
      margin-top: 0;
    }

    .admin-bar .sticky-menu {
      margin-top: 13px;
    }

    .tag-markup-2 li>ul,
    li>ol,
    .pages-content li>ul,
    li>ol {
      padding-left: .5rem;
    }

    .size-full.wp-image-906 {
      width: 100%;
      height: auto;
    }

    .meta-info {

      margin-left: -15px;
      margin-right: -15px;

    }

    .pages-content img {
      width: 100%;
      height: auto;
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .header-btn.second-header-btn {
      display: none;
    }

    .f-cta-area {
      margin-bottom: 0;
    }

    .search-form label {
      width: 100%;
    }

    .has-3-columns .wp-block-column,
    .has-5-columns .wp-block-column,
    .has-6-columns .wp-block-column,
    .has-4-columns .wp-block-column,
    .has-2-columns .wp-block-column,
    .wp-block-media-text.alignwide figure,
    .wp-block-media-text__content,
    .wp-block-gallery.alignwide.columns-4.is-cropped li,
    .wp-block-gallery.columns-6.is-cropped li,
    .wp-block-gallery.columns-7.is-cropped li,
    .wp-block-gallery.columns-8.is-cropped li {
      width: 100%;
    }

    #contact-form {
      padding: 0 15px;
    }

    .s-video-wrap {
      padding-left: 13px;
      padding-right: 13px;
    }

    .wp-block-gallery.columns-3 li {
      width: 100%;
    }

    .wp-block-gallery li {
      width: 50%;
    }

    .main-menu .has-sub>ul {
      display: none;
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;

    }

    .main-menu .has-sub>ul {
      border-top: 1px solid #f7f7f7;
    }

    .main-menu .has-sub>ul>.has-sub>ul {
      display: none;
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;
      margin-top: 0;
      padding-left: 15px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0);
      border-top: 1px solid #f7f7f7;

    }

    .main-menu .has-sub>ul>.has-sub>ul li:last-child {
      border-bottom: none;
    }

    .main-menu .has-sub>ul>.has-sub>ul>.has-sub>ul {
      display: none;
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;
      margin-top: 0;
      background: #f5f5f5;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0);

    }

    .main-menu .has-sub li a {
      padding-left: 20px;
    }

    .menu-item ul.sub-menu .sub-menu li,
    .menu-item ul.sub-menu .sub-menu .sub-menu li {

      border-bottom: none;
    }

    .comment-author-admin {
      margin-left: 0;
    }

    .container-p {
      padding: 0 15px;
    }

    .menu-area {
      padding-top: 15px;
      padding-bottom: 5px;
    }

    .slider-bg {
      min-height: 600px;
    }

    .slider-content h2 {
      font-size: 30px;
      letter-spacing: 0;
      margin-bottom: 30px;
    }

    .down-arrow {
      bottom: 20px;
    }

    .section-t {
      display: none;
    }

    .about-img {
      margin-left: 0;
    }

    .about-img img {
      width: 100%;
    }

    .about-text span {
      top: 20px;
    }

    .about-text {
      height: 109px;
      width: 214px;
    }

    .about-content.pl-30 {
      padding-left: 0;
      margin-top: 30px;
      padding-bottom: 3px;
    }

    .about-title h2 {
      font-size: 36px;
      letter-spacing: -1px;
    }

    .features-content p {
      padding: 0;
    }

    .section-title {
      padding-left: 0;
      padding-right: 0;
    }

    .section-title h2 {
      font-size: 36px;
      letter-spacing: -1px;
    }

    .video-wrap img {
      width: 100%;
    }

    .video-position {
      bottom: 0;
    }

    .video-img a {
      height: 50px;
      width: 50px;
      line-height: 55px;
    }

    .v-list ul li i {
      display: block;
      margin-right: 15px;
      float: left;
      margin-top: 2px;
    }

    .v-list ul li span {
      overflow: hidden;
      display: block;
    }

    .wp-list ul li {
      display: block;
      align-items: unset;
    }

    .wp-list {
      padding: 30px 20px;
      padding-bottom: 25px;
    }

    .wp-icon {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .wp-img {
      margin-right: 0;
      margin-top: 30px;
      margin-bottom: -15px;
    }

    .wp-img>img {
      width: 100%;
    }

    .wp-img .wp-tag {
      left: 0;
      top: 75px;
      width: 25%;
    }

    .counter small {
      top: -20px;
    }

    .cta-content p {
      padding-right: 0;
    }

    .cta-right {
      margin-top: 35px;
    }

    .testimonial-area.pb-120 {
      padding-bottom: 185px;
      padding-top: 113px;
    }

    .blog-content {
      padding: 30px 20px;
    }

    .f-cta-area {
      padding: 35px 20px 0;
    }

    .breadcrumb-area {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .menu-area {
      padding: 20px 0;
    }

    .responsive {
      float: right;
      display: block !important;
      cursor: pointer;
      margin-top: -38px;
    }

    .responsive i {
      color: #fff;
      font-size: 25px;
    }

    #mobile-menu {
      display: none;
      background: #fff;
      padding: 20px 0;
    }

    .main-menu {
      text-align: left !important;
    }

    .main-menu ul li {
      display: block;
      border-bottom: 1px solid #f7f7f7;
      margin-left: 0;
    }

    .button-group button {
      margin: 0 4px;
    }

    .main-menu ul>li>a::before {
      content: none;
    }

    .main-menu ul li a {
      color: #777;
      padding: 12px 15px;
      display: inline-block;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }

    .second-menu {
      margin-bottom: 0;
      box-shadow: none;
      padding: 0;
      margin-top: 0;
    }

    .second-menu::before {
      content: none;
    }

    .second-header {
      padding: 10px 0;
    }

    .second-menu .main-menu ul li {
      margin-left: 0;
    }

    .s-about-img {
      margin-bottom: 20px;
    }

    .s-about-img img {
      width: 100%;
    }

    .s-video-content {
      padding-left: 15px;
    }

    .testimonial-active {
      width: 100%;

    }

    .testimonial-active .slick-arrow {
      width: 87%;
    }

    .portfolio .col3 .grid-item,
    .portfolio .col2 .grid-item {
      width: 100%;
      padding: 15px;
    }

    .contact-img {
      display: none;
    }

    .contact-bg.pb-80 {
      padding-bottom: 120px;
    }

    .single-post {
      box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
    }

    .second-about {
      display: none;
    }

    .s-about-content.pl-30 {
      margin-top: 0;
    }

    .s-video-content h2 {
      font-size: 40px;
    }

    .features-shape {
      display: none;
    }

    .choose-wrap.pl-100 {
      padding-left: 0;
    }

    .choose-content p {
      padding-right: 0;
    }

    .choose-list ul li i {
      display: block;
      margin-right: 15px;
      float: left;
      margin-top: 2px;
    }

    .choose-list ul li span {
      overflow: hidden;
      display: block;
    }

    .inner-wp-icon {
      display: none;
    }

    .wp-thumb.mb-35 {
      margin-bottom: 25px;
    }

    .bsingle__content {
      padding: 25px;
    }

    .bsingle__content h2 {
      padding-right: 0;
      font-size: 24px;
    }

    .bsingle__content p {
      padding-right: 0px;
    }

    .quote-post .quote-icon {
      margin-top: 14px;
      float: unset;
      margin-bottom: 15px;
    }

    .pagination-wrap .pagination {
      text-align: center;
    }

    .widget__post-content h6 {
      font-size: 14px;
      padding-right: 0;
    }

    .widget__banner-overly>span {
      margin-bottom: 120px;
    }

    .widget__banner-overly h3 {
      color: #ffffff;
      font-size: 32px;
      margin-bottom: 100px;
      font-weight: 500;
    }

    .breadcrumb-title h2 {
      font-size: 25px;
    }

    .breadcrumb-title p {
      padding: 0;
      font-size: 15px;
    }

    .breadcrumb-area {
      min-height: 400px;
    }

    .details__content h2 {
      font-size: 24px;
    }

    .details__content blockquote {
      padding-right: 20px;
      padding-left: 45px;
    }

    .details__content figure img {
      float: unset;
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
    }

    .post__tag ul li {
      margin-right: 2px;
    }

    .post__share {
      text-align: left !important;
    }

    .post__share h5 {
      margin-bottom: 15px;
    }

    .post__tag {
      margin-bottom: 35px;
    }

    .posts_navigation .prev-link {
      margin-bottom: 30px;
    }

    .posts_navigation .blog-filter {
      margin-bottom: 30px;
      display: inline-block;
    }

    .related-post-wrap .rp__content {
      padding: 35px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .related-post-wrap .rp__content h3 {
      font-size: 20px;
    }

    .avatar__wrap {
      padding: 50px 20px;
    }

    .single__comment .comments-avatar {
      float: unset;
      margin-bottom: 15px;
    }

    .single__comment.children {
      margin-left: 0;
    }

    .comment__form {
      padding: 30px 20px;
    }

    .b-details-p.pt-120 {
      padding-top: 115px;
    }

    .menu-area .menu-tigger {
      display: none;
    }

    .off-logo {
      display: block;
    }

    .faq-img.text-right {
      text-align: center !important;
      margin-top: 30px;
    }

    .faq-area.pt-113.pb-120 {
      margin-top: 65px;
    }

    .s-slider-content p {
      padding-right: 0;
    }

    .slider-btn.mt-55 {
      margin-top: 25px;
    }

    .btn.ss-btn {
      display: none;
    }

    .faq-img img {
      width: 100%;
    }

    .down-arrow {
      display: none;
    }

    .meta__info ul li {
      margin-right: 15px;
    }

    #scrollUp {
      right: 20px;
      bottom: 30px;
    }

    .video-wrap>img {
      display: none;
    }

    .video-img img {
      width: 100%;
    }

    .video-position {
      position: unset;
      bottom: unset;
      left: unset;
    }

    .counter-area.pb-90 {
      padding-bottom: 85px;
    }

    .cta-bg.pb-120 {
      padding-bottom: 115px;
    }

    .contact-wrapper input {
      height: 65px;
      padding: 0 25px;
    }

    .contact-wrapper textarea {
      height: 160px;
      padding: 30px 25px;
    }

    .second-slider-content {
      padding-top: 150px;
    }

    .s-img {
      margin-left: 90px;
      margin-top: 0px;
      margin-bottom: 30px;
      width: 50%;
    }

    .s-cta-p.pb-120 {
      padding-bottom: 120px;
    }
  }

  /* Large Mobile :480px. */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .slider-content h2 {
      font-size: 34px;
    }

    .nav-sign-in{
      display: block !important;
    }

    .features-content p {
      padding: 0 80px;
    }

    .section-title h2 {
      font-size: 42px;
      letter-spacing: -1px;
    }

    .wp-list {
      padding: 80px 50px;
      padding-bottom: 75px;
    }

    .blog-content h4 {
      font-size: 24px;
    }

    .f-insta ul li {
      width: 28%;
    }

    .f-insta ul li a img {
      width: 100%;
    }

    .s-video-content {
      padding-left: 40px;
      padding-right: 40px;
    }

    .s-video-content br {
      display: none;
    }

    .choose-wrap.pl-100 {
      padding-left: 30px;
    }

    .inner-wp-icon {
      display: block;
    }

    .testimonial-p {
      padding-top: 114px;
    }

    .pricing-box {
      padding: 80px 40px;
    }

    .bsingle__content h2 {
      font-size: 26px;
    }

    .quote-post .quote-icon {
      float: left;
      margin-right: 30px;
      display: block;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .widget__banner-overly>span {
      margin-bottom: 260px;
    }

    .widget__banner-overly h3 {
      margin-bottom: 210px;
      font-size: 60px;
    }

    .avatar__wrap {
      padding: 50px 40px;
    }

  }

}
