@import "./boostrap.scss";
@import "./swiper.scss";

/*
  Theme Name: Xconsulta - Business & Starup HTML5 Template
  Support: admin@gmail.com
  Description: Business  HTML5 template.
  Version: 1.0
*/

/* CSS Index
-----------------------------------
1. Theme default css
2. header
3. slider
4. about
4. about
5. features
6. services
7. video
8. choose
9. brand
10. work-process
11. team
12. counter
13. cta
14. testimonial
15. blog
16. pagination
17. f-cta
18. pricing
19. contact
20. footer
*/

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:400,400i,500,500i,700,700i,900,900i&display=swap');

.landing-page {

  body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #687068;
    font-style: normal;
  }

  .img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
  }

  .f-left {
    float: left
  }

  .f-right {
    float: right
  }

  .fix {
    overflow: hidden
  }

  a,
  .button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  a:focus,
  .button:focus {
    text-decoration: none;
    outline: none;
  }

  a:focus,
  a:hover,
  .portfolio-cat a:hover,
  .footer -menu li a:hover {
    text-decoration: none;
  }

  .btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }

  a,
  button {
    color: #1696e7;
    outline: medium none;
  }

  button:focus,
  input:focus,
  input:focus,
  textarea,
  textarea:focus {
    outline: none;
    box-shadow: none;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #190a32;
    margin-top: 0px;
    font-style: normal;
    font-weight: 600;
    text-transform: normal;
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
  }

  h1 {
    font-size: 40px;
    font-weight: 500;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  ul {
    margin: 0px;
    padding: 0px;
  }

  li {
    list-style: none
  }

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    color: #666666;
    margin-bottom: 15px;
  }

  hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
  }

  label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }

  *::-moz-selection {
    background: #d6b161;
    color: #fff;
    text-shadow: none;
  }

  ::-moz-selection {
    background: #444;
    color: #fff;
    text-shadow: none;
  }

  ::selection {
    background: #444;
    color: #fff;
    text-shadow: none;
  }

  *::-moz-placeholder {
    color: #555555;
    font-size: 14px;
    opacity: 1;
  }

  *::placeholder {
    color: #555555;
    font-size: 14px;
    opacity: 1;
  }

  .theme-overlay {
    position: relative
  }

  .theme-overlay::before {
    background: #1696e7 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .separator {
    border-top: 1px solid #f2f2f2
  }

  /* button style */

  .btn {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 25px #C63A9580;
    border: none;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 0;
    padding: 15px 35px;
    text-align: center;
    text-transform: unset;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
  }

  .btn:hover {
    color: #fff;
  }

  .btn:hover::before {
    opacity: 0;
  }

  .breadcrumb>.active {
    color: #888;
  }

  /* scrollUp */
  #scrollUp {
    background: #ff3494;
    height: 45px;
    width: 45px;
    right: 50px;
    bottom: 77px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 22px;
    line-height: 45px;
    transition: .3s;
  }

  #scrollUp:hover {
    background: #ff3494;
  }

  /* 2. header */
  .header-top {
    padding-top: 40px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 30px;
  }

  .innder-ht {
    padding: 10px 0;
  }

  .wellcome-text p {
    margin-bottom: 0;
    color: #8a8a8a;
  }

  .header-cta ul li {
    display: inline-block;
    margin-left: 35px;
  }

  .header-cta ul li:first-child {
    margin-left: 0;
  }

  .header-cta ul li i {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  .header-top-cta ul li {
    display: inline-block;
    width: 200px;
    margin-right: 80px;
  }

  .header-top-cta ul li:last-child {
    margin-right: 0;
  }

  .h-cta-icon {
    float: left;
    display: block;
    margin-right: 20px;
  }

  .h-cta-content {
    overflow: hidden;
  }

  .h-cta-content h5 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0;
  }

  .h-cta-content p {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .main-menu ul li {
    display: inline-block;
    margin-left: 25px;
    position: relative;
  }

  .main-menu ul li.active a {
    color: #ff3494;
  }

  .main-menu ul li a {
    display: block;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 34px 0;
    position: relative;
  }

  .main-menu ul li:first-child {
    margin-left: 0;
  }

  .menu-area .menu-tigger {
    cursor: pointer;
    display: block;
    float: left;
    margin-right: 40px;
    margin-top: 27px;
  }

  .menu-area .menu-tigger span {
    height: 2px;
    width: 30px;
    background: #2935bb;
    display: block;
    margin: 7px 0;
    transition: .3s;
  }

  .main-menu ul li:hover>a {
    color: #ff3494;
  }

  .main-menu ul li:hover>a::before {
    width: 100%;
  }

  .display-ib {
    display: inline-block;
  }

  .header-social a {
    font-size: 14px;
    display: inline-block;
    margin-left: 5px;
    height: 40px;
    width: 40px;
    background: #e9eaf8;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ff3494;
  }

  .header-social a:hover {
    color: #fff;
    background: #ff3494;
  }

  .sticky-menu {
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);
    z-index: 9999;
    background: #190a32;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);

    .dripicons-align-right {
      color: white !important;
    }
  }

  .responsive {
    display: none;
  }

  #mobile-menu {
    display: block;
  }

  .second-header {
    border: none;
    padding-bottom: 50px;
    background: #f4f4fe;
    padding-top: 10px;
  }

  .second-menu {
    position: relative;
    z-index: 9;
  }

  .sticky-menu .second-menu {
    margin: 0;
    box-shadow: none;
    padding: 0;
  }

  .sticky-menu .second-menu::before {
    content: none;
  }

  .second-menu .main-menu ul li {
    margin-left: 30px;
  }

  .second-menu .main-menu ul li:first-child {
    margin-left: 0;
  }

  .second-menu .main-menu ul li a::before {
    content: none;
  }

  .second-header-btn .btn {
    background: #fff;
    border: 2px solid #fff;
    font-size: 16px;
    text-transform: uppercase;
    color: #190a32;
  }

  .second-header-btn .btn:hover {
    color: #fff;
    background: #ff3494;
    border-color: #ff3494;
  }

  .second-header-btn .btn::before {
    content: none;
  }

  .offcanvas-menu {
    position: fixed;
    right: 0;
    height: 100%;
    width: 300px;
    z-index: 999;
    background: #00081b;
    top: 0;
    padding: 30px;
    transition: .5s;
    transform: translateX(100%);
  }

  .offcanvas-menu.active {
    transform: translateX(0)
  }

  .menu-close i {
    font-size: 18px;
    color: #fff;
    transition: .3s;
    cursor: pointer;
  }

  .menu-close:hover i {
    color: #ff3494;
  }

  .offcanvas-menu ul {
    margin-top: 30px;
  }

  .offcanvas-menu ul li {
    border-bottom: 1px solid #101c38;
  }

  .offcanvas-menu ul li a {
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
    padding: 6px 0;
    display: block;
  }

  .offcanvas-menu ul li:hover a {
    color: #ff3494;
  }

  .side-social a {
    color: #fff;
    margin-right: 10px;
  }

  .side-social {
    margin-top: 30px;
  }

  .side-social a:hover {
    color: #ff3494;
  }

  .offcanvas-menu form {
    position: relative;
    margin-top: 30px;
  }

  .offcanvas-menu form input {
    width: 100%;
    background: none;
    border: 1px solid #2d3547;
    padding: 7px 10px;
    color: #fff;
  }

  .offcanvas-menu form button {
    position: absolute;
    border: none;
    right: 0;
    background: #ff3494;
    padding: 8px 14px;
    top: 0;
    cursor: pointer;
  }

  .offcanvas-menu form button i {
    color: #fff;
  }

  .offcanvas-overly {
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  .offcanvas-overly.active {
    opacity: .5;
    visibility: visible;
  }

  .off-logo {
    display: none;
  }

  /* breadcrumb */
  .breadcrumb-area {
    min-height: 500px;
    background-position: center;
    background-size: cover;
    margin-top: -100px;
  }

  .breadcrumb {
    display: inline-block;
    -ms-flex-wrap: wrap;
    flex-wrap: unset;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: unset;
    border-radius: 0;
  }

  .breadcrumb li {
    display: inline-block;
  }

  .breadcrumb li a {
    font-size: 16px;
    color: #ddd;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 15px;
    padding-left: 15px;
    color: #ddd;
    content: ">";
  }

  .breadcrumb-title h2 {
    font-size: 70px;
    margin-bottom: 25px;
    line-height: 1;
    color: #fff;
  }

  .breadcrumb-title p {
    margin-bottom: 0;
    padding: 0 40px;
    color: #fff;
    font-size: 16px;
  }

  .breadcrumb>.active {
    color: #ff3494;
    font-weight: 500;
  }

  /* 3. slider */

  .slider-bg {
    min-height: 810px;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
  }

  .slider-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: .5;
    z-index: -1;
  }

  .slider-bg2 {
    min-height: 900px;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -118px;
    z-index: 1;
  }

  .slider-bg2 .slider-content>span::before {
    content: "";
    background: #190a32;
    height: 3px;
    float: left;
    width: 60px;
    margin-top: 15px;
    margin-right: 10px;
  }

  .slider-bg2 .slider-content h2 {
    color: #190a32;
    padding-right: 5%;
  }

  .s-img {
    margin-left: 100px;
    margin-top: 190px;
    width: 60%;
  }

  .slick-slider {
    z-index: 999;
  }

  .small-title {
    background: #f7f4fe;
    display: inline-block;
    border-radius: 20px;
  }

  .small-title li {
    display: inline-block;
    padding: 5px 20px;
    color: #190a32;
  }

  .small-title li:first-child {
    background: #7d4196;
    border-radius: 20px;
    color: #fff;
    padding: 5px 30px;
  }

  .slider-bg2 .slider-content p {
    color: #676f67;
    font-size: 16px;
    margin-bottom: 0;
  }

  .slider-bg2 .slider-content>span {
    font-size: 22px;
    font-weight: 500;
    color: #ff3494;
    display: block;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .slider-bg2 .video-i {
    background: #190a32;
    padding: 14px;
    border-radius: 100%;
    width: 51px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin-left: 20px;
  }

  .slider-bg2 .video-i:hover {
    background: #ff3494;
    padding: 14px;
    border-radius: 100%;
    width: 51px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin-left: 20px;
  }

  #particles-js {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .slider-content>span {
    font-size: 16px;
    font-weight: 500;
    color: #ff3494;
    text-transform: uppercase;
    letter-spacing: 6px;
    display: block;
    margin-bottom: 20px;
  }

  .slider-content h2 {
    font-size: 90px;
    color: #fff;
    letter-spacing: -2px;
    line-height: 1.1;
    margin-bottom: 40px;
  }

  .slider-content h2 span {
    color: #ff3494;
  }

  .slider-content p span {
    display: inline-block;
    height: 2px;
    width: 40px;
    background: #ff3494;
    margin-right: 20px;
    position: relative;
    top: -4px;
  }

  .slider-content p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
  }

  .slider-btn .btn {
    background: #fff;
    color: #ff3494;
  }

  .slider-btn .btn::before {
    content: none;
  }

  .slider-btn .btn {
    box-shadow: 3px 3px 0 0 #ff3494;
  }

  .slider-btn .btn:hover {
    box-shadow: none;
  }

  .p-relative {
    position: relative;
  }

  .down-arrow {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    color: #fff;
    z-index: 1;
    height: 60px;
    width: 30px;
    margin: auto;
    text-align: center;
    line-height: 60px;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 18px;
  }

  .down-arrow:hover {
    color: #fff;
  }

  .slider-active .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 70px;
    height: 60px;
    width: 60px;
    color: #ff3494;
    font-size: 18px;
    line-height: 65px;
    border: none;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    z-index: 9;
    cursor: pointer;
    padding: 0;
    box-shadow: 4px 2px 0 0 transparent;
    transition: .5s;
  }

  .slider-active .slick-next {
    right: 70px;
    left: auto;
  }

  .slider-active .slick-arrow:hover {
    box-shadow: 4px 2px 0 0 #ff3494;
  }

  .second-slider-content h2 {
    font-size: 70px;
    margin-bottom: 20px;
  }

  .second-slider-bg::before {
    opacity: .5;
  }

  .second-slider-content {
    padding-top: 285px;
    padding-bottom: 5px;
    z-index: 999;
    position: relative;
  }

  .s-slider-content h2 {
    margin-bottom: 25px;
  }

  .s-slider-content p {
    font-size: 16px;
    padding-right: 50px;
  }

  .btn.ss-btn {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 25px #C63A9580;
    color: #fff;
  }

  .btn.ss-btn:hover {
    background: transparent linear-gradient(90deg, #FF3494 0%, #7D4196 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    box-shadow: 3px 4px 25px #C63A9580;
  }

  .slider-shape {
    position: absolute !important;
    z-index: 9;
  }

  .ss-one {
    top: 0px !important;
    left: -115px !important;
  }

  .ss-two {
    top: 60% !important;
    left: 18% !important;
  }

  .ss-three {
    top: 80% !important;
    left: 8% !important;
  }

  .ss-four {
    top: 85% !important;
    left: 30% !important;
  }

  .ss-five {
    left: 61% !important;
    top: 83% !important;
  }

  .ss-six {
    left: 88% !important;
    top: 80% !important;
  }

  .ss-seven {
    top: 20% !important;
    left: 89% !important;
  }

  .ss-eight {
    top: 70px !important;
    left: 48% !important;
  }

  /* 4. about */
  .about-img {
    margin-left: -50px;
  }

  .about-text {
    position: absolute;
    bottom: 65px;
    right: 30px;
    height: 135px;
    width: 265px;
    text-align: center;
    background: #ff3494;
    box-shadow: 5px 5px 0 0 #ff3494;
  }

  .about-text span {
    font-size: 24px;
    color: #fff;
    font-style: italic;
    top: 30px;
    position: relative;
  }

  .about-title>span {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    background: #ff3494;
    height: 30px;
    display: inline-block;
    width: 110px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .about-title h2 {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 35px;
  }

  .about-title p span {
    display: inline-block;
    height: 2px;
    width: 40px;
    background: #ff3494;
    margin-right: 20px;
    position: relative;
    top: -5px;
  }

  .about-title p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .about-content p {
    margin-bottom: 45px;
  }

  .section-t h2 {
    font-size: 350px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
    color: #190a32;
    opacity: .05;
    margin: 0;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-style: italic;
    top: -35px;
    line-height: 1;
  }

  .second-about {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: 0;
    bottom: 0;
  }

  .second-atitle>span {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ff3494;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 20px;
    background: transparent;
  }

  .s-about-content p {
    margin-bottom: 28px;
  }

  /* 5. features */
  .features-content h4 {
    font-size: 24px;
    margin-bottom: 17px;
  }

  .features-content p {
    padding: 0 40px;
    margin-bottom: 25px;
  }

  .s-btn {
    font-size: 14px;
    font-weight: 500;
    color: #190a32;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 9px 30px;
    border-radius: 50px;
  }

  .s-btn:hover {
    background: #ff3494;
    border-color: #ff3494;
    color: #fff;
  }

  .section-title span {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ff3494;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 20px;
  }

  .section-title h2 {
    font-size: 38px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    position: relative;
    line-height: inherit;
  }

  .section-title h2::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    width: 60px;
    background: #ff3494;
    margin: auto;
    transition: .3s;
    display: none;
  }

  section:hover .section-title h2::before {
    width: 100px;
  }

  .features-p {
    padding-top: 380px;
  }

  .features-icon img {
    border: 5px solid transparent;
    border-radius: 50%;
    transition: .5s;
  }

  .s-features:hover .features-icon img {
    border: 5px solid #ff3494;
  }

  .features-shape {
    position: absolute;
    z-index: -1;
  }

  .fshape-one {
    left: 120px;
    top: 25%;
  }

  .fshape-two {
    left: 15%;
    top: 51%;
  }

  .fshape-three {
    top: 69%;
    left: 6%;
  }

  .fshape-four {
    top: 40%;
    left: 89%;
  }

  .fshape-five {
    top: 71%;
    left: 83%;
  }

  /* 6. services */
  .services-area.gray-bg {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .single-services {
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .single-services::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 5px;
    top: 5px;
    background: #43d4a9;
    z-index: -1;
    transition: .3s;
    opacity: 0;
  }

  .service-t h2 {
    top: 55px;
  }

  .services-thumb img {
    width: 100%;
  }

  .services-content span {
    display: block;
    height: 2px;
    width: 150px;
    background: #ff3494;
    transition: .3s;
    margin-bottom: 55px;
  }

  .services-content {
    padding: 60px 40px;
    padding-bottom: 55px;
  }

  .services-content small {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 20px;
  }

  .services-content h4 {
    font-size: 24px;
    margin-bottom: 23px;
  }

  .services-content h4:hover a {
    color: #ff3494;
  }

  .services-content p {
    margin-bottom: 0;
  }

  .single-services:hover .services-content span {
    background: #ff3494;
  }

  .single-services:hover::before {
    opacity: 1;
  }

  .services-active .slick-dots {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    line-height: 1;
  }

  .services-active .slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }

  .services-active .slick-dots li button {
    text-indent: -99999px;
    border: none;
    padding: 0;
    height: 5px;
    width: 20px;
    background: #ff3494;
    border-radius: 50px;
    z-index: 1;
    cursor: pointer;
    transition: .3s;
  }

  .services-active .slick-dots li.slick-active button {
    width: 50px;
    background: #ff3494;
  }

  .services-active .slick-track {
    padding-bottom: 10px;
  }

  .services-icon {
    margin-bottom: 35px;
  }

  .services-icon i {
    font-size: 30px;
    background: #f5f8fa;
    border-radius: 50%;
    color: #190a32;
    height: 75px;
    width: 75px;
    line-height: 75px;
    text-align: center;
  }

  .services-two .services-icon {
    float: left;
  }

  .services-two .second-services-content {
    float: left;
    width: 70%;
    margin-left: 20px;
  }

  .services-two .s-single-services {
    transition: .3s;
    padding: 40px 15px 22px;
    border: 5px solid #f5f8fa;
    margin-bottom: 30px;
  }

  .services-two .s-single-services {
    float: left;
  }

  .second-services-content h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    transition: .3s;
  }

  .second-services-content p {
    margin-bottom: 18px;
  }

  .second-services-content a {
    font-size: 22px;
    font-weight: 500;
    color: #ff3494;
    position: relative;
    transition: .5s;
    width: 56px;
    height: 56px;
    display: inline-block;
    line-height: 56px;
    border-radius: 50%;
    background-clip: border-box;
    position: relative;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    padding: 1px;
  }

  .second-services-content a span {
    background: #fff;
    display: inline-block;
    width: 100%;
    border-radius: 50%;
    height: 100%;
  }

  .s-single-services:hover .second-services-content a span {
    background: none;
  }

  .second-services-content a::after {
    left: -40px;
    right: unset;
    opacity: 0;
  }

  .s-single-services {
    transition: .3s;
    padding: 0 30px;
    /*border: 5px solid #f5f8fa;*/
  }

  .s-single-services:hover {
    background: #fff;
  }

  .s-single-services:hover .second-services-content h5 {
    color: #ff3494 !important;
  }

  .s-single-services:hover .second-services-content a {
    color: #ff3494 !important;
  }

  .s-single-services:hover {
    border-color: #ff3494 !important;
    background: #eeffef !important;
  }

  .s-single-services:hover .services-icon i {
    color: #fff;
    background: #ff3494 !important;
  }

  .s-single-services:hover .second-services-content h5 {
    color: #ff3494 !important;
  }

  .s-single-services:hover .second-services-content a {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 3px 4px 25px #C63A9580 !important;
    color: #fff !important;
    border: none;
  }

  .services-bg {
    background-position: center;
    background-size: cover;
  }

  /* 7. video */
  .video-position {
    position: absolute;
    bottom: -170px;
    left: 50px;
  }

  .video-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .video-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ff3494;
    opacity: .65;
  }

  .video-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    font-size: 14px;
    color: #ff3494;
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    background: #fff;
    border-radius: 50%;
  }

  .video-img a::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 90px;
    width: 90px;
    background: rgba(255, 255, 255, 0.479);
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }

    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }

  .left-align h2::before {
    margin: unset;
  }

  .video-content p {
    margin-bottom: 30px;
  }

  .v-list ul li {
    margin-bottom: 20px;
  }

  .v-list ul li:last-child {
    margin-bottom: 0px;
  }

  .v-list ul li i {
    color: #ff3494;
    display: inline-block;
    margin-right: 5px;
  }

  .v-padding {
    padding-top: 35px;
  }

  .s-video-wrap {
    background-position: center;
    background-size: cover;
    margin-bottom: -415px;
    height: 550px;
    display: flex;
    align-items: center;
    margin-top: 55px;
  }

  .s-video-content {
    padding-left: 200px;
  }

  .s-video-content a {
    z-index: 9;
    font-size: 14px;
    color: #ff3494;
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    background: #fff;
    border-radius: 50%;
    display: block;
    margin-bottom: 25px;
    position: relative;
  }

  .s-video-content a::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 90px;
    width: 90px;
    background: rgba(255, 255, 255, 0.479);
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
  }

  .s-video-content h2 {
    font-size: 55px;
    color: #fff;
    font-weight: 600;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  .s-video-content p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
  }

  /* 8. choose */
  .chosse-img {
    background-size: 100%;
    height: 569px;
    left: 0;
    position: absolute;
    top: 9%;
    width: 738px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .chosse-img2 {
    left: 12.5%;
    position: absolute;
    top: 3%;
  }

  .chosse-img2 img {
    width: 35%;
  }

  .choose-list ul li {
    margin-bottom: 20px;
    color: #666666;
  }

  .choose-list ul li i {
    color: #ff3494;
    display: inline-block;
    margin-right: 5px;
  }

  .choose-area {
    background-size: cover;
    background-position: center;
  }

  .choose-content p {
    margin-bottom: 15px;
    color: #666666;
    padding-right: 50px;
  }

  .choose-btn a {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 25px #C63A9580;
    border-radius: 10px;
    width: 241px;
    display: inline-block;
    color: #fff;
    display: flex;
    padding: 10px 30px;
    float: left;
    margin-right: 25px;
    position: relative;
  }

  .choose-btn a .icon {
    padding: 0 20px 0 0;
    line-height: 50px;
    z-index: 99;
  }

  .choose-btn a .text {
    z-index: 99;
    font-size: 20px;
  }

  .choose-btn a .text strong {
    font-size: 22px;
    margin-top: -5px;
    display: block;
    font-weight: 500;
  }

  .choose-btn a.g-btn {
    box-shadow: none;
    width: 262px;
  }

  .g-btn::before {
    content: "";
    position: absolute;
    height: 98%;
    width: 99.1%;
    background: #fff;
    left: 1px;
    top: 1px;
    border-radius: 9px;
  }

  .g-btn .text {
    color: #7D4196;
  }

  .g-btn .text strong {
    color: #FF3494;
  }

  /* 9. brand */
  .single-brand {
    text-align: center;
  }

  .single-brand img {
    display: inline-block;
  }

  /* 10. work-process */
  .wp-bg {
    background-position: center;
    background-size: cover;
  }

  .w-title h2 {
    color: #190a32;
  }

  .w-title h2::before {
    background-color: #ff3494;
  }

  .wp-list {
    background: #232c8e;
    box-shadow: 0px 10px 30px 0px rgba(29, 38, 129, 0.48);
    padding: 80px 50px;
    padding-bottom: 75px;
  }

  .wp-list ul li {
    display: flex;
    align-items: start;
    overflow: hidden;
    margin-bottom: 45px;
  }

  .wp-list ul li:last-child {
    margin-bottom: 0px;
  }

  .wp-icon {
    margin-right: 25px;
  }

  .wp-content h5 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 15px;
  }

  .wp-content p {
    color: #fff;
    margin-bottom: 0;
  }

  .wp-tag {
    position: absolute;
    left: 70px;
    top: 90px;
    animation: alltuchtopdown 3s infinite;
    -webkit-animation: alltuchtopdown 3s infinite;
    animation-delay: 0s;
    -webkit-animation-delay: 1s;
  }

  @keyframes alltuchtopdown {
    0% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }

    50% {
      -webkit-transform: rotateX(0deg) translateY(-10px);
      -moz-transform: rotateX(0deg) translateY(-10px);
      -ms-transform: rotateX(0deg) translateY(-10px);
      -o-transform: rotateX(0deg) translateY(-10px);
      transform: rotateX(0deg) translateY(-10px);
    }

    100% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }
  }

  @-webkit-keyframes alltuchtopdown {
    0% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }

    50% {
      -webkit-transform: rotateX(0deg) translateY(-10px);
      -moz-transform: rotateX(0deg) translateY(-10px);
      -ms-transform: rotateX(0deg) translateY(-10px);
      -o-transform: rotateX(0deg) translateY(-10px);
      transform: rotateX(0deg) translateY(-10px);
    }

    100% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }
  }

  .wp-img {
    margin-right: -60px;
    margin-top: -60px;
    margin-bottom: -15px;
  }

  .wp-thumb img {
    width: 100%;
  }

  .inner-wp-icon {
    float: left;
    display: block;
    margin-right: 30px;
  }

  .inner-wp-c {
    overflow: hidden;
    display: block;
  }

  .inner-wp-c h5 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .inner-wp-c p {
    margin-bottom: 0;
  }

  /* 11. team */

  .team-thumb img {
    width: 100%;
  }

  .team-info {
    background: #fff;
    transform: translateY(0);
    padding: 20px;
    transition: .3s;
  }

  .team-info h4 {
    font-size: 20px;
    margin-bottom: 5px;
    transition: .3s;
  }

  .team-info span {
    font-size: 16px;
    transition: .3s;
  }

  .team-social a {
    font-size: 14px;
    color: #666666;
    margin: 0 5px;
    display: inline-block;
  }

  .single-team:hover .team-info {
    transform: translateY(-45px);
    background: #ff3494;
  }

  .single-team:hover .team-info h4 {
    color: #fff;
  }

  .single-team:hover .team-info span {
    color: #fff;
  }

  .single-team:hover .team-info a {
    color: #fff;
  }

  .team-t h2 {
    top: 50px;
  }

  /* 12. counter */
  .count {
    font-size: 55px;
    color: #ff3494;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    margin-bottom: 13px;
    line-height: 1;
    display: inline-block;
  }

  .counter small {
    font-size: 44px;
    font-weight: 500;
    color: #ff3494;
    line-height: 1;
    position: relative;
    top: -35px;
    right: 0;
    font-family: 'Roboto', sans-serif;
  }

  .single-counter p {
    font-size: 16px;
    margin-bottom: 0;
  }

  /* 13. cta */
  .cta-bg {
    position: relative;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .cta-title h2 {
    color: #fff;
    padding-bottom: 0;
    font-size: 70px;
  }

  .cta-title p {
    color: #fff;
    font-size: 18px;
  }

  .cta-title h2::before {
    content: none;
  }

  .cta-content p {
    color: #fff;
    padding-right: 80px;
    margin-bottom: 35px;
  }

  .cta-btn .btn::before {
    background: #fff;
  }

  .cta-right p {
    margin-bottom: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
  }

  .call-tag {
    margin-bottom: 20px;
    animation: alltuchtopdown 3s infinite;
    -webkit-animation: alltuchtopdown 3s infinite;
    animation-delay: 0s;
    -webkit-animation-delay: 1s;
  }

  /* 14. testimonial */
  #testimonios {
    position: relative;
  }

  #testimonios::after {
    content: "";
    position: absolute;
    width: 300px;
    height: 100%;
    background: url(../../images/landing/shape/header-sape8.png);
    top: 0;
  }

  .single-testimonial {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 15px #0000001A;
    border-radius: 10px;
    margin-left: 30px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
  }

  .single-testimonial .qutation {
    position: absolute;
    right: 8%;
    top: 8%;
  }

  .testimonial-area h2,
  .testimonial-area p {}

  .ta-bg {
    height: 475px;
    width: 475px;
    background-repeat: no-repeat;
    margin-top: 25px;
  }

  .testimonial-author .ta {
    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
  }

  .author-one.now-in-view {
    top: -25px;
    left: 110px;
  }

  .author-two.now-in-view {
    top: 21%;
    left: 69%;
  }

  .author-three.now-in-view {
    left: 67%;
    top: 66%;
  }

  .author-four.now-in-view {
    top: 77%;
    left: 53px;
  }

  .author-five.now-in-view {
    left: 50px;
    top: 41%;
  }

  .testi-author img {
    margin-top: -14px;
    width: 118px;
    height: 118px;
  }

  .ta-info {
    overflow: hidden;
    display: block;
    padding-top: 20px;
  }

  .ta-info h6 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .ta-info span {
    font-size: 14px;
    color: #7D4196;
  }

  .testi-author {
    overflow: hidden;
    display: flex;
    margin-left: -78px;
  }

  .single-testimonial p {
    padding-left: 42px;
    margin-bottom: 0;
    margin-top: -20px;
  }

  .testimonial-active .slick-arrow {
    position: absolute;
    bottom: -50px;
    left: 0;
    border: none;
    background: none;
    padding: 0;
    font-size: 24px;
    color: #fff;
    z-index: 9;
    cursor: pointer;
    transition: .3s;
    width: 94%;
  }

  .testimonial-active i {
    color: #f63594;
    right: 40px;
  }

  .testimonial-active .slick-next {
    left: 40px;
  }

  .testimonial-active .slick-arrow:hover {
    color: #ff3494;
  }

  .testimonial-avatar.p-relative {
    min-height: 455px;
    display: block;
    overflow: hidden;
  }

  .testimonial-avatar .ta {
    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
  }

  .avatar-one.now-in-view {
    top: 0%;
    left: 28%;
  }

  .avatar-two.now-in-view {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .avatar-three.now-in-view {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .avatar-four.now-in-view {
    top: 20%;
    left: 70%;
  }

  .avatar-five.now-in-view {
    top: 70%;
    left: 25%;
  }

  .avatar-six.now-in-view {
    top: 59%;
    left: 64%;
  }

  /* faq */
  .faq-btn {
    font-size: 17px;
    color: #fff;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 30px;
    width: 100%;
    text-align: left;
    padding-right: 55px;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
  }

  .card .collapse.show {
    display: block;
    padding-top: 20px;
  }

  .faq-btn.collapsed {
    background: no-repeat;
    color: #190a32;
  }

  .faq-wrap .card-header:first-child {
    border-radius: 0;
  }

  .faq-wrap .card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: unset;
    border-bottom: none;
  }

  .faq-wrap .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: unset;
    padding: 25px 30px;
    padding-top: 0;
  }

  .faq-wrap .card-header h2 {
    font-size: unset;
  }

  .faq-wrap .card {
    border: none;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 1px 3px 11px #00000026;
  }

  .faq-wrap .card:last-child {
    margin-bottom: 0;
  }

  .faq-wrap .card-header h2 button::after {
    position: absolute;
    content: "\f06e";
    top: 19px;
    right: 30px;
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #fff;
  }

  .faq-wrap .card-header h2 button.collapsed::after {
    content: "\f070";
    color: #000;
  }

  /* 15. blog */
  .blog-thumb img {
    width: 100%;
  }

  .blog-content {
    background: #fff;
    padding: 40px 30px;
    position: relative;
    z-index: 1;
  }

  .blog-content .admin {
    display: block;
    border-top: 1px solid #d7d7d7;
    padding-top: 30px;
  }

  .blog-content .admin ul {
    display: flex;
  }

  .blog-content .admin img {
    background: #D7D7D7 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 12px #00000026;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    margin-right: 20px;
  }

  .b-meta ul li {
    display: inline-block;
    margin-right: 5px;
  }

  .b-meta ul li:last-child {
    margin-right: 0;
  }

  .b-meta ul li a {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 15px #D22D4A4D;
    border-radius: 13px;
    padding: 5px 20px;
  }

  .b-meta ul li a.corpo {
    color: #ff3494;
  }

  .b-meta ul li a:hover {
    color: #fff;
  }

  .blog-content h4 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.3;
  }

  .blog-content h4:hover a {
    color: #ff3494;
  }

  .blog-btn {
    background: #f4f4fe;
    color: #190a32;
  }

  .blog-btn:hover {
    color: #fff;
    background: #ff3494;
  }

  .blog-content p {
    margin-bottom: 35px;
  }

  .single-post {
    background: #FFFFFF;
    overflow: hidden;
    box-shadow: 3px 4px 25px #0000001A;
    border-radius: 10px;
    transition: .3s;
  }

  .single-post:hover {
    box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
  }

  .single-post:hover .blog-btn {
    color: #fff;
    background: #ff3494;
  }

  .single-post.active {
    box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
  }

  .single-post.active .blog-btn {
    color: #fff;
    background: #ff3494;
  }

  .bsingle__post-thumb img {
    width: 100%;
  }

  .bsingle__post .video-p {
    position: relative;
  }

  .bsingle__post .video-p .video-i {
    height: 80px;
    width: 80px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 80px;
    border-radius: 50%;
    background: #fff;
    color: #ff3494;
  }

  .blog-active .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    border: none;
    background: none;
    padding: 0;
    font-size: 30px;
    color: #fff;
    z-index: 9;
    opacity: .4;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    cursor: pointer;
  }

  .blog-active .slick-arrow.slick-next {
    right: 40px;
    left: auto;
  }

  .blog-active .slick-arrow:hover {
    opacity: 1;
  }

  .bsingle__content {
    padding: 50px;
    border: 2px solid #f5f8fa;
  }

  .bsingle__content .meta-info ul {
    margin-bottom: 7px;
  }

  .bsingle__content .meta-info ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 30px;
  }

  .bsingle__content .meta-info ul li a {
    color: #5f708f;
    text-transform: capitalize;
  }

  .bsingle__content .meta-info ul li a i {
    margin-right: 5px;
  }

  .bsingle__content .meta-info ul li a:hover {
    color: #ff3494;
  }

  .bsingle__content .meta-info ul li i {
    margin-right: 5px;
  }

  .bsingle__content h2 {
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .bsingle__content h2:hover a {
    color: #ff3494;
  }

  .bsingle__content p {
    padding-right: 35px;
    margin-bottom: 25px;
  }

  .bsingle__content .blog__btn .btn {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
  }

  .bsingle__content .blog__btn .btn::before {
    content: none;
  }

  .bsingle__content .blog__btn .btn:hover {
    background: transparent linear-gradient(90deg, #FF3494 0%, #7D4196 100%) 0% 0% no-repeat padding-box;
    ;
    color: #fff;
  }

  .quote-post {
    background-position: center;
    background-repeat: no-repeat;
  }

  .quote-post .quote-icon {
    float: left;
    margin-right: 30px;
    display: block;
    margin-top: 20px;
  }

  .quote-post h2 {
    overflow: hidden;
    margin-bottom: 0;
  }

  .widget {
    padding: 35px 25px 35px 25px;
    border: 2px solid #f5f8fa;
    overflow: hidden;
  }

  .widget-title {
    margin-bottom: 40px;
  }

  .widget-title h4 {
    font-size: 28px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 600;
  }

  .slidebar__form {
    position: relative;
  }

  .slidebar__form input {
    background: #f5f8fa;
    border: none;
    width: 100%;
    padding: 18px 30px;
  }

  .slidebar__form input::placeholder {
    font-size: 12px;
    color: #666666;
  }

  .slidebar__form button {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    padding: 18px 23px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    height: 100%;
  }

  .widget-insta-post li {
    display: inline-block;
    margin: 5px 3px;
  }

  .widget-insta-post li:hover a::before {
    opacity: .7;
  }

  .widget-insta-post li a {
    position: relative;
    display: block;
  }

  .widget-insta-post li a::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #ff3494;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  .widget-social a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    display: inline-block;
    background: transparent;
    border: 1px solid #f5f8fa;
    color: #666666;
    margin: 0 3px;
  }

  .widget-social a:hover {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    border-color: #fff;
  }

  .cat__list li {
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
  }

  .cat__list li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }

  .cat__list li:hover a {
    color: #ff3494;
  }

  .cat__list li a {
    font-size: 14px;
    color: #666666;
  }

  .cat__list li a span {
    float: right;
    display: block;
  }

  .widget__post ul li {
    margin-bottom: 20px;
    overflow: hidden;
  }

  .widget__post ul li:last-child {
    margin-bottom: 0;
  }

  .widget__post-thumb {
    float: left;
    display: block;
    margin-right: 20px;
  }

  .widget__post-content {
    overflow: hidden;
    display: block;
  }

  .widget__post-content h6 {
    font-size: 15px;
    margin-bottom: 1px;
    padding-right: 15px;
    font-weight: 500;
  }

  .widget__post-content h6:hover a {
    color: #ff3494;
  }

  .widget__post-content span {
    font-size: 14px;
  }

  .widget__post-content span i {
    margin-right: 8px;
  }

  .widget__tag ul li {
    display: inline-block;
    margin: 5px 3px;
  }

  .widget__tag ul li:hover a {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    border-color: #fff;
    color: #ffffff;
  }

  .widget__tag ul li a {
    display: block;
    border: 1px solid #d8d8d8;
    font-size: 14px;
    color: #666666;
    padding: 9px 20px;
  }

  .widget.widget__banner {
    border: none;
    padding: 0;
    position: relative;
  }

  .widget__banner-thumb img {
    width: 100%;
  }

  .widget__banner-overly {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .widget__banner-overly>span {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 160px;
  }

  .widget__banner-overly h3 {
    color: #ffffff;
    font-size: 40px;
    margin-bottom: 129px;
    font-weight: 500;
  }

  .widget__banner-overly h3 span {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 300;
  }

  .widget__banner-overly .btn {
    border-radius: unset;
    background: #fff;
    border: 2px solid #fff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 17px 35px;
    color: #ff3494;
  }

  .widget__banner-overly .btn:hover {
    background: transparent;
    color: #fff;
  }

  .meta__info ul {
    margin-bottom: 7px;
  }

  .meta__info ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 30px;
  }

  .meta__info ul li a {
    color: #666666;
    text-transform: capitalize;
  }

  .meta__info ul li a i {
    margin-right: 5px;
  }

  .meta__info ul li a:hover {
    color: #ff3494;
  }

  .meta__info ul li i {
    margin-right: 5px;
  }

  .details__content h2 {
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 20px;
    padding-right: 0;
    font-weight: 600;
  }

  .details__content p {
    margin-bottom: 18px;
  }

  .details__content blockquote {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    padding: 30px 35px;
    padding-left: 90px;
    position: relative;
    font-size: 14px;
    padding-right: 50px;
    margin-top: 45px;
    margin-bottom: 45px;
    line-height: 1.7;
    color: #fff;
    z-index: 1;
  }

  .details__content blockquote::before {
    position: absolute;
    content: "";
    left: 19px;
    top: 29px;
    background: url(../../images/landing/icon/blockquote_d.png);
    height: 86px;
    width: 92px;
    z-index: -1;
    opacity: 0.2;
  }

  .details__content blockquote footer {
    font-size: 14px;
    margin-top: 15px;
    color: #fff;
    font-weight: 600;
  }

  .details__content-img {
    margin: 45px 0;
  }

  .details__content-img img {
    width: 100%;
  }

  .details__content figure {
    margin-top: 45px;
    margin-bottom: 60px;
  }

  .details__content figure img {
    float: left;
    width: 255px;
    margin-right: 30px;
  }

  .post__tag h5 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .post__tag ul li {
    display: inline-block;
    margin-right: 15px;
  }

  .post__tag ul li a {
    font-size: 14px;
    text-transform: uppercase;
    border: 2px solid #e5e5e5;
    padding: 12px 18px;
    font-weight: 500;
    display: inline-block;
    border-radius: 3px;
    color: #666666;
  }

  .post__tag ul li a:hover {
    border-color: #fff;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
  }

  .post__share h5 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  .post__share ul li {
    margin-left: 20px;
    display: inline-block;
  }

  .post__share ul li a {
    font-size: 18px;
    display: inline-block;
    color: #666666;
  }

  .post__share ul li a:hover {
    color: #ff3494;
  }

  .posts_navigation {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  .posts_navigation .prev-link span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .posts_navigation .prev-link h4 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: capitalize;
  }

  .posts_navigation .prev-link h4:hover a {
    color: #ff3494;
  }

  .posts_navigation .next-link span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .posts_navigation .next-link h4 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
  }

  .posts_navigation .next-link h4:hover a {
    color: #ff3494;
  }

  .related__post .post-title {
    margin-bottom: 35px;
  }

  .related__post .post-title h4 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .related-post-wrap .post-thumb img {
    width: 100%;
  }

  .related-post-wrap .rp__content {
    padding: 44px;
    border: 2px solid #f4f3fb;
    border-top: none;
  }

  .related-post-wrap .rp__content h3 {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 1.4;
  }

  .related-post-wrap .rp__content h3:hover a {
    color: #ff3494;
  }

  .related-post-wrap .rp__content p {
    margin-bottom: 0;
    font-size: 14px;
  }

  .avatar__wrap {
    background: #f5f8fa;
    padding: 50px 80px;
  }

  .avatar__wrap .avatar-img {
    margin-top: -115px;
    margin-bottom: 35px;
  }

  .avatar__wrap-content p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .avatar__info h5 {
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .avatar__info-social {
    margin-bottom: 20px;
  }

  .avatar__info-social a {
    font-size: 12px;
    color: #9498b6;
    display: inline-block;
    margin: 0 5px;
  }

  .avatar__info-social a:hover {
    color: #ff3494;
  }

  .comment__wrap {
    border-bottom: 1px solid #eaeaea;
  }

  .comment__wrap-title {
    margin-bottom: 35px;
  }

  .comment__wrap-title h5 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .single__comment .comments-avatar {
    float: left;
    width: 100px;
    margin-right: 30px;
  }

  .single__comment.children {
    margin-left: 130px;
  }

  .single__comment.children .avatar-name h6 i {
    font-size: 12px;
    color: #666666;
    margin-left: 20px;
  }

  .comment-text {
    overflow: hidden;
  }

  .comment-text .avatar-name {
    overflow: hidden;
  }

  .comment-text .avatar-name h6 {
    font-size: 18px;
    margin-bottom: 7px;
    font-weight: 600;
  }

  .comment-text .avatar-name span {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ff3494;
  }

  .comment-text .avatar-name .comment-reply {
    float: right;
    display: inline-block;
    border: 2px solid #f4f4ff;
    padding: 8px 18px;
    border-radius: 50px;
    font-size: 14px;
    margin-top: -23px;
    color: #666666;
  }

  .comment-text .avatar-name .comment-reply:hover {
    border-color: #fff;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
  }

  .comment-text .avatar-name .comment-reply i {
    margin-right: 5px;
  }

  .comment-text p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .comment__form {
    padding: 50px;
    background: #f5f8fa;
  }

  .comment__form .comment-field {
    position: relative;
  }

  .comment__form .comment-field.text-area i {
    top: 25px;
    transform: unset;
  }

  .comment__form .comment-field i {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    color: #ff3494;
    display: none;
    font-size: 14px;
  }

  .comment__form .comment-field textarea {
    height: 150px;
    width: 100%;
    padding: 20px 30px;
    padding-right: 50px;
    background: #ffffff;
    border: none;
  }

  .comment__form .comment-field textarea::placeholder {
    font-size: 14px;
    color: #a7a7c1;
  }

  .comment__form .comment-field textarea:focus {
    outline: 1px solid #ff3494;
  }

  .comment__form .comment-field input {
    width: 100%;
    padding: 20px 30px;
    padding-right: 50px;
    background: #ffffff;
    border: none;
  }

  .comment__form .comment-field input::placeholder {
    font-size: 14px;
    color: #a7a7c1;
  }

  .comment__form .comment-field input:focus {
    outline: 1px solid #ff3494;
  }

  .comment__form .btn {
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: none;
    margin-top: 10px;
  }

  .comment__form .btn::before {
    content: none;
  }

  .comment__form .btn:hover {
    color: #fff;
    background: transparent linear-gradient(90deg, #FF3494 0%, #7D4196 100%) 0% 0% no-repeat padding-box;
  }

  /* 16. pagination */
  .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: block;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
  }

  .pagination .page-item {
    display: inline-block;
    margin: 0 5px;
  }

  .pagination .page-item a.page-link {
    border-radius: 50%;
    padding: 0;
    height: 70px;
    width: 70px;
    line-height: 70px;
    background: #f8f8f8;
    color: #9fa7c5;
    font-size: 14px;
    border: none;
    font-weight: 500;
  }

  .pagination .page-item:hover a.page-link {
    box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
    background: #ff4a57;
    color: #fff;
  }

  .pagination .page-item.active a.page-link {
    box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
    background: #ff4a57;
    color: #fff;
  }

  .pagination-wrap .pagination {
    display: block;
    border-radius: unset;
  }

  .pagination-wrap .pagination li {
    display: inline-block;
    margin-right: 6px;
    margin-left: 0;
    margin-bottom: 0;
  }

  .pagination-wrap .pagination li.active a {
    background: #ff3494;
    color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(26, 35, 126, 0.32);
  }

  .pagination-wrap .pagination li a {
    border: none;
    height: 50px;
    width: 50px;
    display: block;
    line-height: 50px;
    background: #ff3494;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  .pagination-wrap .pagination li a:hover {
    color: #ffffff;
    background: #ff3494;
  }

  /* 17. f-cta */
  .f-cta-area {
    background: #fff;
    margin-left: 260px;
    margin-right: 260px;
    position: relative;
    top: -70px;
    margin-bottom: 30px;
    box-shadow: 0 -3px 65px 0 rgba(0, 0, 0, .09);
  }

  .f-cta-icon i {
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background: #ff3494;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .single-cta {
    border-bottom: 1px solid;
  }

  div.single-cta:last-child {
    border: 0;
  }

  .single-cta h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .single-cta p {
    margin-bottom: 0;
  }

  .single-cta p a {
    color: #ff3494;
  }

  .s-cta-btn .btn {
    background: #190a32;
    color: #fff;
    margin-top: 10px;
  }

  .s-cta-btn .btn::before {
    content: none;
  }

  /* 18. pricing */

  .pricing-tab {
    text-align: center;
  }

  .pricing-tab span {
    vertical-align: middle;
  }

  .pricing-tab .pricing-tab-switcher {
    height: 30px;
    width: 60px;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    display: inline-block;
    border-radius: 50px;
    position: relative;
    transition: all 0.3s ease;
    margin: 0 18px;
    transform: translateY(-3px);
    cursor: pointer;
  }

  .pricing-tab .pricing-tab-switcher::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 3px;
    background: #fff;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    transition: all 0.3s ease;
  }

  .pricing-tab .pricing-tab-switcher.active::before {
    left: calc(100% - 30px);
  }

  .pricing-amount {
    position: relative;
    color: #ff3494;
  }

  .pricing-amount .annual_price {
    display: none;
  }

  .pricing-amount .monthly_price {
    display: block;
    float: left;
    width: 100%;
  }

  .pricing-amount .currency {
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    top: -16px;
    left: -22px;
  }

  .pricing-amount .price {
    font-size: 60px;
    font-weight: 600;
  }

  .pricing-amount .subscription {
    font-size: 16px;
    font-weight: 500;
  }

  .pricing-amount.change-subs-duration .monthly_price {
    display: none;
  }

  .pricing-amount.change-subs-duration .annual_price {
    display: block;
    float: left;
    width: 100%;
  }


  .poppuler {
    position: absolute;
    width: 100%;
    top: -20px;
    left: 0;
  }

  .poppuler .btn {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    border-color: #fff;
    padding: 10px 28px;
    font-size: 15px;
  }

  .pricing-box {
    float: left;
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 5px 25px #00000033;
    border: 3px solid #fff;
    background: #fff;
  }

  .pricing-btn {
    position: absolute;
    bottom: -20px;
    width: 100%;
    left: 0;
  }

  .pricing-head {
    padding: 30px 0 0;
    float: left;
    width: 100%;

  }

  .pricing-head h4 {
    font-size: 30px;
    font-weight: 600;
    color: #7D4196;
    margin-bottom: 10px;
  }

  .pricing-head h5 {
    color: #7D4196;
    font-size: 25px;
    font-weight: 500;
    float: left;
    width: 100%;
    margin-top: 15px;
  }

  .price-count h2 {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .price-count small {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: -20px;
    margin-right: 3px;
  }

  .price-count span {
    font-size: 18px;
    font-weight: 400;
    color: #666666;
  }

  .pricing-body {
    padding: 0px 40px 35px;
  }

  .pricing-body li {
    margin-top: 15px;
  }

  .pricing-body li::before {
    content: '';
    font-family: 'Font Awesome 5 Free';
    color: #ff3494;
    margin-right: 10px;
  }

  .pricing-body p {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }

  .pricing-btn .btn {
    color: #fff;
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    text-transform: uppercase;
    box-shadow: 3px 4px 25px #C63A9580;
    padding: 15px 35px;
  }

  .pricing-btn .btn::before {
    content: none;
  }

  .pricing-btn .btn:hover {
    background: #ff3494;
    color: #fff;
  }

  .pricing-box.active {
    border: 3px solid #e9369447;
  }

  .pricing-box.active .price-count span {
    color: #fff;
  }

  .pricing-body {
    float: left;
    width: 100%;
  }

  .pricing-box.active .price-count h2 {
    color: #fff;
  }

  .pricing-box.active .pricing-btn .btn {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    border-color: #fff;
  }

  /* 19. contact */
  .contact-area {
    background-repeat: no-repeat;
    background-position: center center;
  }

  .contact-img2 {
    margin-left: -160px;
  }

  .contact-wrapper textarea {
    border: 0;
    color: #000;
    font-size: 15px;
    height: 200px;
    width: 100%;
    text-transform: capitalize;
    transition: .3s;
    background: #f4f4fe;
    padding: 30px 40px;
  }

  .contact-wrapper textarea::-moz-placeholder {
    color: #b3bdcd;
    font-size: 14px;
  }

  .contact-wrapper textarea::placeholder {
    color: #8990b0;
    font-size: 14px;
  }

  .c-icon i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    color: #8990b0;
    font-size: 14px;
  }

  .contact-message.c-icon i {
    top: 30px;
    transform: unset;
  }

  .contact-wrapper input {
    border: 0;
    color: #000;
    font-size: 15px;
    height: 80px;
    text-transform: capitalize;
    width: 100%;
    padding: 0 40px;
    transition: .3s;
    background: #f4f4fe;
  }

  .contact-wrapper input::-moz-placeholder {
    color: #8990b0;
    font-size: 14px;
  }

  .contact-wrapper input::placeholder {
    color: #8990b0;
    font-size: 14px;
  }

  .contact-name {
    position: relative;
  }

  .contact-field label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #190a32;
    margin-bottom: 20px;
    cursor: unset;
  }

  .contact-field input {
    width: 100%;
    border: none;
    background: #fff;
    padding: 10px 20px;
    transition: .3s;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 15px #0000001A;
    border-radius: 10px;
  }

  .contact-field.c-name::after {
    content: "\f007";
  }

  .contact-field::after {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    font-size: 14px;
    text-align: center;
    right: 25px;
    color: #ff3494;
    display: none;
    bottom: 22px;
  }

  .contact-field.c-email::after {
    content: "\f0e0";
  }

  .contact-field.c-subject::after {
    content: "\f249";
  }

  .contact-field.c-message::after {
    content: "\f303";
    font-weight: 700;
    top: 20px;
    bottom: unset;
  }

  .contact-field input::placeholder {
    color: #9e9e9e;
  }

  .contact-field textarea {
    width: 100%;
    border: none;
    background: #fff;
    padding: 10px 20px;
    transition: .3s;
    height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 15px #0000001A;
    border-radius: 10px;
  }

  .contact-bg {
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .contact-img {
    position: absolute;
    bottom: 0;
    right: 170px;
    z-index: -1;
  }

  /* 20. footer */
  .footer-bg {
    background: transparent linear-gradient(90deg, #7D4196 0%, #FF3494 100%) 0% 0% no-repeat padding-box;
    ;
  }

  .f-cta-area.gray-bg {
    background: #f4f4fe;
    border-top: 3px solid #ff3494;
  }

  .footer-text p {
    color: #ddd;
    margin-bottom: 0;
  }

  .footer-social span {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    margin-right: 20px;
  }

  .footer-social a {
    font-size: 15px;
    color: #ff3494;
    margin-right: 15px;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    text-align: center;
    line-height: 40px;
    box-shadow: 3px 4px 15px #00000026;
    border-radius: 50%;
  }

  .footer-social a:hover {
    color: #ff3494;
  }

  .f-widget-title h5 {
    color: #fff;
    font-size: 19px;
    margin-bottom: 30px;
    padding-bottom: 25px;
    position: relative;
  }

  .f-widget-title h5::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 94px;
    background: url(../../images/landing/bg/tf-line.png) no-repeat;
  }

  .footer-link ul li a {
    color: #fff;
    font-size: 14px;
    display: inline-block;
  }

  .footer-link ul li a:hover {
    padding-left: 5px;
    color: #ff3494;
  }

  .footer-link ul li {
    margin-bottom: 10px;
  }

  .footer-link ul li:last-child {
    margin-bottom: 0;
  }

  .f-insta ul li {
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 7px;
  }

  .f-insta ul li a {
    display: block;
    position: relative;
  }

  .f-insta ul li a::before {
    content: "\e035";
    font-family: "dripicons-v2" !important;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #ff3494;
    transition: .3s;
    opacity: 0;
    z-index: 9;
  }

  .f-insta ul li a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ff3494;
    opacity: 0;
    transition: .3s;
  }

  .f-insta ul li:hover a::before {
    top: 50%;
    opacity: 1;
  }

  .f-insta ul li:hover a::after {
    opacity: .6;
  }

  .copyright-text {
    border-top: 2px solid #ffffff3b;
    padding: 10px 0 20px;
  }

  .copyright-text p {
    margin-bottom: 0;
    color: #ddd;
  }

  .f-contact li {
    float: left;
    margin-bottom: 20px !important;
    width: 100%;
  }

  .f-contact i {
    width: 40px;
    height: 40px;
    background: #fff;
    line-height: 40px;
    color: #ff3494 !important;
    text-align: center;
    border-radius: 50%;
    float: left;
  }

  .f-contact span {
    float: left;
    color: #fff;
    margin-left: 8px;
    width: 77%;
  }

  /* Fillter css */

  .portfolio .col2 .grid-item {
    width: 49%;
    padding: 15px;
  }

  .portfolio .col3 .grid-item {
    width: 33.33%;
    padding: 15px;
  }

  .gallery-image figcaption {
    position: absolute;
    bottom: -50px;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    color: #222;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    font-weight: 500;
  }

  .gallery-image:hover figcaption {
    opacity: 1;
    bottom: 0px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

  }

  .button-group {
    padding-bottom: 15px;
  }

  .button-group button {
    border: none;
    background: none;
    transition: .3s;
    font-size: 14px;
    margin: 0 15px;
    padding-bottom: 15px;
    cursor: pointer;
    outline: none;
    color: #000a2d;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
  }

  .button-group button:hover {
    color: #ff3494;
  }

  .button-group button.active {
    background: transparent;
    color: #ff3494;
    box-shadow: inset 0 -2px 0 #ff3494;
  }

  /* 21. Menu Theme dropdown default css */
  .single-cta {
    line-height: 28px;
  }

  .single-cta a {
    color: #190a32;
  }

  .main-menu ul li.has-sub .current a {
    color: #222;
  }

  .main-menu ul li.current a {
    color: #ff3494;
  }

  .main-menu .has-sub>ul {
    display: none;
    position: absolute;
    background-color: #ffff;
    min-width: 250px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    margin-top: 15px;
    border-top: 4px solid #ff3494;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  }

  .main-menu .has-sub li a {
    padding: 15px;
    color: #000;

  }

  .main-menu .has-sub li {
    margin-left: 0 !important;
    float: left;
    border-bottom: 1px solid #ddd;
    width: 100%;
    text-align: left;

  }

  .main-menu .has-sub a:hover {
    color: #ff3494;
  }

  .main-menu .has-sub:hover>ul {
    display: block;
    margin-top: 0px;
  }

  .main-menu .has-sub>ul>.has-sub>ul {
    display: none;
    position: absolute;
    background-color: #ffff;
    width: 250px;
    z-index: 1;
    padding: 0;
    margin-left: -255px;
    margin-top: 0;
    top: -3px;
  }

  .main-menu .has-sub>ul>.has-sub:hover>ul {
    display: block;
  }

  .main-menu .has-sub>ul>.has-sub>ul>.has-sub>ul {
    display: none;
    position: absolute;
    background-color: #ffff;
    width: 250px;
    z-index: 1;
    padding: 0;
    margin-left: 255px;
    margin-top: 0;
    top: -3px;
  }

  .main-menu .has-sub>ul>.has-sub>ul>.has-sub:hover>ul {
    display: block;
  }

  /* 23. mega menu */

  .offcanvas-menu .has-mega-menu ul {
    display: none;
  }

  .offcanvas-menu li.has-sub.has-mega-menu:hover>ul {
    margin-left: -1160px;
    background: #00081b;
    top: 121px;
  }

  .offcanvas-menu .mega-menu-column.has-sub ul li a {
    color: #fff;
    font-size: 14px;
  }

  .offcanvas-menu .mega-menu-column.has-sub ul li a:hover {
    color: #ff3494;
  }

  .has-mega-menu {
    position: static;
  }

  li.has-sub.has-mega-menu ul {
    position: absolute;
    top: NNpx;
    /*insert the needed value*/
    z-index: 100;
    left: 0px;
    right: 0px;
    width: 1180px;

  }

  .main-menu .mega-menu-column.has-sub a {
    color: #333;
  }

  .main-menu .mega-menu-column.has-sub a:hover {
    color: #ff3494;
  }

  li.has-sub.has-mega-menu:hover>ul {
    /*flexbox fallback for browsers that do not support CSS GRID lyout*/
    display: flex;
    flex-wrap: wrap;
    /*CSS GRID lyout*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    margin-left: -820px;

  }

  li.has-sub.has-mega-menu:hover>ul>li>ul {
    /*flexbox fallback for browsers that do not support CSS GRID lyout*/
    display: flex;
    flex-wrap: wrap;
    width: auto;
    background: none;
    /*CSS GRID lyout*/
    display: grid;
    position: relative;
    border: none;
    margin-left: 0;
  }


  @media screen and (min-width: 992px) {
    li.has-mega-menu:hover>ul {
      grid-template-columns: repeat(auto-fit, minmax(33.3333%, 1fr));
    }
  }

  @media screen and (min-width: 1200px) {
    li.has-mega-menu:hover>ul {
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
  }

  @media screen and (min-width: 600px) {
    li.mega-menu-column {
      width: 100%;
      max-width: 100%;
      min-height: 1px;
      padding: 10px 25px;
      flex: 1 0 calc(50%);
    }
  }

  @media screen and (min-width: 992px) {
    li.mega-menu-column {
      flex: 1 0 calc(33.333%);
    }
  }

  .main-menu .has-sub .mega-menu-column li {
    flex: 1 0 calc(25%);
    border: none;
  }


  /* 22. Theme sidebar widge css */
  .sidebar-widget a {
    color: #777;
  }

  .tag-cloud-link {
    font-size: 14px !important;
    border: 2px solid #f6f6f6;
    padding: 10px 19px !important;
    display: inline-block;
    margin: 10px 4px;
    text-transform: capitalize;
    float: none !important;
    width: auto !important;
  }

  .tag-cloud-link:hover {
    border: 2px solid #ff3494;
    color: #6a6a6a;
  }

  .widget_text img {
    width: 100%;
    height: auto;
  }

  .sidebar-widget select {
    width: 100%;
    padding: 10px;
    border: 2px solid #f6f6f6;
  }

  .recentcomments a {
    color: #190a32;
  }

  .sidebar-widget a.rsswidget {
    color: #190a32;
  }

  #wp-calendar caption {
    color: #190a32;
    font-weight: 500;
    font-size: 14px;
  }

  .quote-post {
    background: url(../../images/landing/bg/quote_bg.png);
  }

  .widget_media_image a:hover {
    padding-left: 0 !important;
  }

  .sidebar-widget .widget li .children {

    border-top: 1px solid #f6f6f6;
    margin-top: 10px;
    padding-bottom: 0px;
    display: inline-block;
    width: 100%;

  }

  .sidebar-widget .widget li.page_item_has_children
    {
    padding-bottom: 0;
  }

  .sidebar-widget .widget .children {
    padding-left: 15px;
  }

  .sidebar-widget .widget .children ul.children {
    padding-left: 30px;
  }

  .sidebar-widget .page_item_has_children li {

    padding-top: 10px;
    display: block;

  }

  .sidebar-widget .widget .children li {
    padding-top: 10px;
  }

  .sidebar-widget .widget .children .children li {
    padding-top: 10px;
  }

  .sidebar-widget .widget li:last-child,
  .sidebar-widget .widget .children li:last-child,
  .sidebar-widget .widget .children .children li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .sticky .bsingle__content,
  .tag-sticky-2 {

    background: #f6f6f6;

  }

  .sticky .bsingle__content ul li {

    margin-bottom: 15px;
    list-style: disc;
    margin-left: 10px;

  }

  #attachment_907 {
    width: 100% !important;
  }

  .wp-image-907 {
    width: 100% !important;
    height: auto;
  }

  .sidebar-widget .widget a {
    width: 85%;
    text-align: left;
  }

  .sidebar-widget .widget a:hover {
    color: #ff3494;
    padding-left: 10px;
  }

  .sidebar-widget .widget .widget_archive li:hover,
  .widget_categories li:hover,
  .sidebar-widget .widget_archive li:hover {
    color: #ff3494;
  }

  .post-password-form input[type="password"] {
    background: #f6f6f6;
    border: none;
    width: 300px;
    padding: 10px 30px;
  }

  .post-password-form input[type="submit"] {
    border: none;
    background: #190a32;
    padding: 10px 30px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
  }

  .post-password-form input[type="submit"]:hover {
    background: #ff3494;
  }

  .wp-block-cover__video-background {
    width: 100%;
  }

  .sidebar-widget .widget_nav_menu .sub-menu {
    padding-left: 15px;
  }

  .page #comments {
    float: left;
    width: 100%;
    margin-top: 50px;
  }

  video {
    width: 100%;
  }

  .sidebar-widget .widget_text a:hover {
    padding-left: 0;
  }

  .dsnone {
    display: none;
  }

  .wp-block-media-text__media img {
    width: 100%;
  }

  .wp-block-image .alignleft {
    float: left;
    margin-right: 1em;
  }

  .wp-block-image figure.alignright {
    margin-left: 1.5em;
  }

  .wp-block-image .alignright {
    float: right;
    margin-left: 1em;
  }

  .wp-block-image img {
    max-width: 100%;
  }

  .wp-block-galler {
    overflow: hidden;
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .blocks-gallery-item {
    margin: 0;
    position: relative;
  }

  .blocks-gallery-item img,
  .blocks-gallery-item img:hover {
    background: 0 0;
    border: none;
    box-shadow: none;
    max-width: 100%;
    padding: 0;
    vertical-align: middle;
  }

  .blocks-gallery-item figcaption {
    background: #eee;
    background: rgba(255, 255, 255, .8);
    color: #333;
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    text-indent: 10px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    opacity: 0;
  }

  .blocks-gallery-item:hover figcaption {
    opacity: 1;
  }

  .wp-block-gallery.columns-3 li {
    width: 33.333%;
    padding: 10px;
    height: 200px;
  }

  .wp-block-gallery.columns-2 li {
    width: 50%;
    padding: 10px;
  }

  .wp-block-gallery {
    width: 100%;
    float: left;
  }

  .wp-block-gallery.columns-5 li {
    width: auto;
    height: auto;
    overflow: hidden;
    columns: auto;
  }

  .bsingle__content .alignright {
    float: right;
    margin-left: 1.5em;
  }

  .bsingle__content .alignleft {
    float: left;
    margin-right: 1em;
  }

  .alignright {
    float: right;
    margin-left: 1.5em;
  }

  .alignleft {
    float: left;
    margin-right: 1em;
  }

  figure.aligncenter {

    width: 100% !important;
    text-align: center;
    float: left;
  }

  .aligncenter {
    margin: auto;
    display: inherit;
  }

  .page-links {
    float: left;
    width: 100%;
    margin-top: 50px;
  }

  #comments {
    float: left;
    width: 100%;
  }

  .bsingle__content ul,
  .pages-content ul {
    padding-left: 0;
  }

  .comment-text ul {
    padding-left: 20px;
  }

  .bsingle__content ul li,
  .comment-text ul li,
  .pages-content ul li {
    list-style: disc;
  }

  .bsingle__content table th,
  .bsingle__content table td,
  .comment-text table th,
  .comment-text table td,
  .pages-content table th,
  .pages-content table td {
    border: 1px solid #f6f6f6;
    padding: 10px;
  }

  .bsingle__content table,
  .comment-text table,
  .pages-content table {
    margin-bottom: 20px;
  }

  .wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile {
    padding: 30px;
  }

  .bsingle__content ol {
    padding: 0 0 0 1.5em;
  }

  .bsingle__content ol li,
  .comment-text ol li,
  .pages-content ol li {
    list-style: decimal;
  }

  .bsingle__content figure {
    margin: 0 0 1rem;
  }

  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    overflow-wrap: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  .has-text-color.has-background.has-very-light-gray-color {
    color: #fff;
    padding: 21px;
  }

  .wp-block-cover-text {
    color: #fff;
    padding: 30px;
  }

  .tag-template img {
    width: auto !important;
  }

  .error-page .error-code {
    display: block;
    font-size: 150px;
    line-height: 150px;
    color: #333;
    margin-bottom: 20px;
    text-shadow: 5px 5px 1px rgba(0, 0, 0, .1);
  }

  .error-body a {

    margin-top: 30px;
    margin-bottom: 100px;

  }

  .tag-markup-2 li>ul,
  li>ol,
  .pages-content li>ul,
  li>ol {
    padding-left: 1.5em;
  }

  .tag-markup-2 ul li,
  .tag-markup-2 ol li,
  .comment-text ul li,
  .comment-text ol li,
  .pages-content ul li,
  .pages-content ol li {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .parent-pageid-1725 figure.aligncenter {
    margin: 50px 0;
  }

  .bsingle__content ul ul li,
  .pages-content ul ul li {
    list-style: circle;
  }

  footer #wp-calendar caption {
    color: #fff;
  }

  ol {
    padding-left: 15px;
  }

  iframe {
    width: 100%;
    height: auto;
  }

  .comment-list li {

    margin-bottom: 30px;

  }

  .wp-block-button__link {
    border: none;
    font-weight: 700;
    padding: .76rem 1rem;
    outline: none;
    outline: none;
    display: inline-block;
    background: #190a32;
    color: #fff !important;
    width: auto;
    border-radius: 5px;
    text-align: center;
  }

  .wp-block-button.aligncenter .wp-block-button__link {

    width: 50%;
    display: block;
    margin: auto;

  }

  .wp-block-button.is-style-outline .wp-block-button__link {

    background: none;
    border: 2px solid #190a32;
    color: #190a32 !important;

  }

  .wp-block-button.is-style-squared .wp-block-button__link {
    border-radius: 0;
  }

  .has-2-columns,
  .has-3-columns,
  .has-5-columns,
  .has-6-columns {
    float: left;
    width: 100%;
  }

  .has-2-columns .wp-block-column {
    float: left;
    width: 50%;
    padding-right: 20px;
  }

  .has-3-columns .wp-block-column {
    float: left;
    width: 33.333%;
    padding-right: 20px;
  }

  .has-5-columns .wp-block-column {
    float: left;
    width: 20%;
    padding-right: 20px;
  }

  .has-4-columns .wp-block-column {
    float: left;
    width: 25%;
    padding-right: 20px;
  }

  .has-6-columns .wp-block-column {
    float: left;
    width: 16.66666666666667%;
    padding-right: 20px;
  }

  .wp-block-media-text.alignwide figure {

    float: left;
    width: 50%;
    margin-right: 20px;

  }

  .wp-block-media-text.alignwide .has-large-font-size {
    font-size: 28px;
    color: #190a32;
  }

  .wp-block-media-text__content {

    float: left;
    width: 47%;
    padding-top: 0;

  }

  .wp-block-columns.alignwide.has-3-columns blockquote {
    margin-top: 0;
    padding-left: 20px;
  }

  .columns-3.is-cropped li {
    list-style: none;
    float: left;
    width: 33.333%;
  }

  .wp-block-gallery.alignleft.columns-2.is-cropped li {

    float: left;
    list-style: none;

  }

  .wp-block-gallery.alignwide.columns-4.is-cropped li {

    float: left;
    list-style: none;
    width: 25%;
    padding: 10px;

  }

  .wp-block-gallery.columns-6.is-cropped li {

    float: left;
    width: 16.666%;
    height: 129px;
    list-style: none;
    padding: 10px;

  }

  .wp-block-gallery.columns-7.is-cropped li {

    float: left;
    width: 14%;
    padding: 10px;
    list-style: none;
    min-height: 150px;

  }

  .wp-block-gallery.columns-8.is-cropped li {

    float: left;
    width: 12%;
    padding: 10px;
    list-style: none;
    min-height: 100px;

  }

  .wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile {
    float: left;
  }

  .wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile figure {
    float: left;
    width: 50%;
    margin-right: 20px;
  }

  .footer-widget .widgettitle,
  .footer-widget .cat-item,
  .footer-widget .widget ul li {
    text-align: left;
    color: #fff;
  }

  .footer-widget select {
    width: 100%;
    padding: 10px;
    border: 2px solid #f6f6f6;
  }

  .blog-deatails-box.single .single {
    display: none;
  }

  .footer-widget .tag-cloud-link,
  .footer-widget p {
    color: #fff;
  }

  .redux-footer h2 {
    margin-top: 0px;
  }

  .footer-widget td {
    padding: 5px;
    text-align: center;
  }

  .footer-widget table {
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
  }

  .footer-widget th {
    background: #f6f6f6;
    padding: 10px 5px;
    text-align: center;
    color: #190a32;
  }

  .bsingle__content h1,
  .bsingle__content h2,
  .bsingle__content h3,
  .bsingle__content h4,
  .bsingle__content h5,
  .bsingle__content h6 {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .pages-content h1,
  .pages-content h2,
  .pages-content h3,
  .pages-content h4,
  .pages-content h5,
  .pages-content h6 {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .comment-list h1,
  .comment-list h2,
  .comment-list h3,
  .comment-list h4,
  .comment-list h5,
  .comment-list h6 {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .inner-linke-page {
    font-size: 16px;
    font-weight: 500;
    color: #190a32;
  }

  .inner-linke-page a {
    border: none;
    height: 32px;
    width: 32px;
    display: inline-block;
    line-height: 32px;
    background: #ff3494;
    border-radius: 50%;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  .inner-linke-page a:hover {
    color: #ffffff;
    background: #190a32;
  }

  .inner-linke-page>span {
    border: none;
    height: 32px;
    width: 32px;
    display: inline-block;
    line-height: 32px;
    font-weight: 400;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    background: #190a32;
    color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32);
  }

  pre {
    padding: 30px;
    background: #f6f6f6;
  }

  pre cite {
    color: #000;
  }

  .inner-linke-page {
    margin-bottom: 50px;
  }

  .attachment-aports-featured-large {
    width: 100%;
    height: auto;
  }

  .quote-post {
    min-height: 200px;
  }

  /* 0. Woocommcrece Theme default css */
  .woocommerce ul.products li.product .price ins {
    background: 0 0;
    font-weight: 400;
    display: inline-block;
  }

  .short-ul {
    margin-bottom: 50px;
  }

  .woocommerce .cst-woocommerce .cart .button,
  .woocommerce .cart input.button,
  .woocommerce .cst-woocommerce #respond input#submit.alt,
  .woocommerce .cst-woocommerce a.button.alt,
  .woocommerce .cst-woocommerce button.button.alt,
  .woocommerce .cst-woocommerce input.button.alt,
  .woocommerce .cst-woocommerce #respond input#submit,
  .woocommerce .cst-woocommerce a.button,
  .woocommerce .cst-woocommerce button.button,
  .woocommerce .cst-woocommerce input.button,
  .woocommerce .cart .button,
  .woocommerce .cart input.button,
  .woocommerce #respond input#submit,
  .woocommerce a.button,
  .woocommerce button.button,
  .woocommerce input.button {
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    background: #ff3494;
    border: none;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 0;
    padding: 15px 30px;
    text-align: center;
    text-transform: unset;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
  }

  .woocommerce-cart .page,
  .woocommerce-account .page,
  .woocommerce-checkout .page {
    width: 100%;
  }

  .cst-woocommerce .columns-3 .button.product_type_simple.add_to_cart_button.ajax_add_to_cart {
    left: 30%;
  }

  .cst-woocommerce .columns-3 .button.product_type_variable.add_to_cart_button {
    left: 28%;
  }

  .woocommerce div.product div.images .flex-control-thumbs {
    margin-top: 30px;
  }

  .woocommerce div.product div.images .flex-control-thumbs img {
    width: auto;
  }

  .woocommerce .summary .price {
    font-size: 18px !important;
    font-weight: 500;
  }

  .woocommerce-review-link .count,
  .woocommerce-review-link {
    font-size: 14px;
    color: #ababab;

  }

  .woocommerce .star-rating {
    color: #f1d700;
  }

  .woocommerce #content div.product div.images,
  .woocommerce div.product div.images,
  .woocommerce-page #content div.product div.images,
  .woocommerce-page div.product div.images {
    float: left;
    width: 48%;
    overflow: hidden;
  }

  .woocommerce .button.product_type_simple.add_to_cart_button.ajax_add_to_cart,
  .woocommerce .button.product_type_variable.add_to_cart_button,
  .woocommerce .button.product_type_grouped {
    top: 37%;
    opacity: 0;
    position: absolute !important;
    left: 21%;

  }

  .woocommerce .products li:hover .button.product_type_simple.add_to_cart_button.ajax_add_to_cart,
  .woocommerce .products li:hover .button.product_type_variable.add_to_cart_button,
  .woocommerce .products li:hover .button.product_type_grouped {

    opacity: 1;
    margin-top: 0px;
  }

  .woocommerce ul.products li.product .onsale {
    top: 0;
    right: 0;
    left: auto;
    margin: 0.5em 0.5em 0 0;
    min-height: 2.236em;
    min-width: 5.236em;
    background-color: #f00;
    border-radius: 0;
    line-height: 2.236;
    font-weight: 500;
    text-transform: uppercase;
  }

  .woocommerce span.onsale {
    margin: 15px;
    min-height: 2.236em;
    min-width: 5.236em;
    background-color: #f00;
    border-radius: 0;
    line-height: 2.236;
    font-weight: 500;
    text-transform: uppercase;
  }

  .posted_in a {
    color: #666666;
    font-weight: 400;
  }

  .woocommerce div.product form.cart {
    margin: 2em 0;
  }

  .woocommerce .quantity .qty {
    width: 90px;
    line-height: 30px;
    border: 1px solid #ccc;
    padding: 3px 8px;
    margin-top: 3px;
    margin-bottom: 7px;
    margin-right: 7px;
  }

  .woocommerce ul.products li.product .woocommerce-loop-category__title,
  .woocommerce ul.products li.product .woocommerce-loop-product__title,
  .woocommerce ul.products li.product h3 {
    font-size: 18px;
  }

  .woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
    vertical-align: middle;
  }

  .woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label label a {
    margin-top: 10px;
    color: #190a32;
    display: inline-block;
  }

  .woocommerce ul li {
    list-style: none !important;
  }

  .woocommerce div.product form.cart .group_table td {
    border: 0;
    vertical-align: middle;
  }

  .woocommerce .orderby {
    width: 200px;
    line-height: 44px;
    border: 1px solid #ccc;
    padding: 8px 10px;
    margin-top: -14px;
    background: #f5f5f5;
    color: #5a5a5a;
  }

  .woocommerce .product_meta {
    font-weight: 500;
    color: #190a32;
  }

  .woocommerce .sku {
    font-weight: 400;
    color: #666666;
  }

  .woocommerce div.product .woocommerce-tabs ul.tabs li.active::before,
  .woocommerce div.product .woocommerce-tabs ul.tabs li::after {
    box-shadow: inherit !important;
    border-radius: 0;
    border: none;
  }

  .woocommerce .sku_wrapper {
    display: block;
    margin-bottom: 10px;
  }

  .woocommerce ul.products li.product,
  .woocommerce-page ul.products li.product {
    background: #f5f5f566;
    text-align: center;
    padding-bottom: 20px;
  }

  .woocommerce div.product .woocommerce-tabs ul.tabs {
    padding: 0 0 0 0;
    margin: 0 0 30px 0;
  }

  .woocommerce div.product .woocommerce-tabs ul.tabs li a {
    font-weight: 500;
  }

  .woocommerce div.product .woocommerce-tabs ul.tabs li {
    border: none;
    background: none;
    border-radius: 0 0 0 0;
    margin: 0 0px;
    padding: 10px 3em;
  }

  .woocommerce div.product .woocommerce-tabs ul.tabs li.active {
    background: #ff3494;
    z-index: 2;
    border-bottom-color: #ff3494;
    border: none;
    color: #fff;
    border-radius: 0;
  }

  .woocommerce-tabs.wc-tabs-wrapper {
    margin-top: 50px;
    display: inline-block;
    width: 100%;
  }

  .woocommerce div.product p.price,
  .woocommerce div.product span.price,
  .woocommerce ul.products li.product .price {
    color: #ff3494;
    font-size: 12px;

  }

  .woocommerce-tabs.wc-tabs-wrapper h2 {

    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    transition: .3s;

  }

  .related.products h2,
  .woocommerce-order-details h2,
  .woocommerce-customer-details h2 {

    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    transition: .3s;

  }

  .woocommerce-cart-form th,
  .shop_table.woocommerce-checkout-review-order-table th,
  .shop_table.order_details th,
  .account-orders-table th {

    background: #190a32;
    padding: 10px 0;
    color: #fff;

  }

  .woocommerce #content table.cart td.actions .input-text,
  .woocommerce table.cart td.actions .input-text,
  .woocommerce-page #content table.cart td.actions .input-text,
  .woocommerce-page table.cart td.actions .input-text {

    width: 173px;
    padding: 12px;
    border-radius: 30px;

  }

  .woocommerce ul.products li.product .star-rating {
    font-size: .857em;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .cst-woocommerce .page-title {
    display: none;
  }

  .woocommerce form .form-row-first,
  .woocommerce form .form-row-last,
  .woocommerce-page form .form-row-first,
  .woocommerce-page form .form-row-last {
    width: 100%;
    overflow: visible;
  }

  .woocommerce form .form-row label {
    line-height: 2;
    width: 100%;
  }

  .woocommerce-input-wrapper {
    width: 100%;
  }

  .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row textarea,
  .woocommerce form .form-row select {

    box-sizing: border-box;
    width: 100%;
    margin: 0;
    outline: 0;
    line-height: normal;
    float: left;
    padding: 10px;
    border: 1px solid #ccc;

  }

  .col2-set {
    margin-bottom: 50px;
  }

  .woocommerce-MyAccount-navigation li {
    background: #190a32;
    padding: 15px 25px;
    margin-bottom: 3px;
  }

  .woocommerce-MyAccount-navigation li:hover {
    background: #ff3494;
    padding: 15px 25px;
    margin-bottom: 10px;
  }

  .woocommerce-MyAccount-navigation li a {
    color: #fff;
  }

  .woocommerce-account .woocommerce-MyAccount-content {
    float: right;
    width: 68%;
    background: #f5f5f5;
    padding: 50px;
    min-height: 500px;
  }

  .woocommerce #respond input#submit.alt,
  .woocommerce a.button.alt,
  .woocommerce button.button.alt,
  .woocommerce input.button.alt {
    background-color: #190a32;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

  .woocommerce #respond input#submit.alt:hover,
  .woocommerce a.button.alt:hover,
  .woocommerce button.button.alt:hover,
  .woocommerce input.button.alt:hover {
    background-color: #ff3494;
    color: #fff;
  }

  .wc-prodcut {
    background: #fff;
    transition: .3s;
  }

  .wc-prodcut:hover {
    box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
  }

  .wc-prodcut:hover .blog-btn {
    color: #fff;
    background: #ff3494;
  }

  .wc-prodcut.active {
    box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
  }

  .wc-prodcut.active .blog-btn {
    color: #fff;
    background: #ff3494;
  }

  .wc-content {
    background: #fff;
    padding: 40px 30px;
    text-align: center;
    z-index: 1;
  }

  .wc-content h4 {
    font-size: 19px;
    line-height: 1.3;
  }

  .wc-prodcut .product-buttons a {
    background: #ff3494;
    color: #fff;
    padding: 15px 30px;
    float: left;
    position: absolute;
    top: 30%;
    left: 32%;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    opacity: 0;
  }

  .wc-prodcut:hover .product-buttons a {
    opacity: 1;
    top: 40%;
  }

  .woocommerce-Price-amount.amount {
    color: #ff3494;
    font-size: 1.25em;
    font-weight: 500;
  }

  .woocommerce nav.woocommerce-pagination ul li {
    border-right: none;
  }

  .woocommerce nav.woocommerce-pagination ul {
    border: none;
  }

  .button.product_type_external {
    display: none !important;
  }

  /* 16. pagination */
  .woocommerce-pagination .page-numbers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: block;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
  }

  .woocommerce-pagination .page-numbers .page-numbers {
    display: inline-block;
    margin: 0 5px;
  }

  .woocommerce-pagination .page-numbers .page-numbers {
    border: none;
    height: 50px;
    width: 50px;
    display: block;
    line-height: 40px;
    background: #ff3494;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  .woocommerce-pagination .page-numbers .page-numbers:hover {
    box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
    background: #190a32;
    color: #fff;
  }

  .woocommerce-pagination .page-numbers .page-numbers.current {
    background: #190a32;
    color: #ffffff;
  }

  .pagination-wrap .pagination {
    display: block;
    border-radius: unset;
  }

  .pagination-wrap .pagination li {
    display: inline-block;
    margin-right: 6px;
    margin-left: 0;
    margin-bottom: 0;
  }

  .pagination-wrap .pagination li.active a {
    background: #190a32;
    color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(26, 35, 126, 0.32);
  }

  .pagination-wrap .pagination li a {
    border: none;
    height: 50px;
    width: 50px;
    display: block;
    line-height: 50px;
    background: #ff3494;
    border-radius: 50%;
    color: #000;
    font-size: 14px;
    text-align: center;
  }

  .pagination-wrap .pagination li a:hover {
    color: #ffffff;
    background: #190a32;
  }

  /* Here's the new CSS to add... */

  .admin-bar .menu-area {
    margin-top: 15px;
  }

  .admin-bar .second-header {
    margin-bottom: -70px;
  }

  .admin-bar .sticky-menu {
    top: 32px;
    margin-top: 0;
  }



  /* 8. app-work */

  .app-work-content {
    /*background: url(../img/bg/how-line.png) no-repeat;*/
    background-position: 45px center;
  }

  .app-work-content h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .app-work-content li {
    display: flex;
    margin-bottom: 40px;
  }

  .app-work-content li .text {
    padding: 10px 30px 0;
  }

  .app-work-content li .text p {
    margin-bottom: 0;
  }

  .app-work-content li:first-child,
  .app-work-content li:last-child {
    padding-left: 0;
  }

  /* 8. video-area */
  .video-area::before {
    background: #f9f7ff;
    content: "";
    width: 100%;
    height: 90%;
    position: absolute;
    bottom: 0;
  }

  .video-wrap h2 {
    margin-top: 50px;
  }

  .video-img2 {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 999;
  }

  .video-img2 img {
    border-radius: 0px 10px 10px 0px;
  }

  .video-img2 .popup-video {
    margin-left: -73px;
  }

  .video-img3 {
    right: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .video-wrap {
    padding-left: 60px;
  }

  .video-content li {
    display: flex;
    color: #190a32;
    margin-bottom: 10px;
  }

  .video-content li .icon {
    padding-right: 8px;
  }

  /* 8. screen-area */
  .swiper-container {
    width: 100%;
    padding-top: 15px !important;
    padding-bottom: 50px !important;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px !important;
    height: auto !important;

  }

  .swiper-slide-active img {
    position: relative;
    padding: 10px;
    left: 7px;
    top: 0;
  }

  .swiper-slide-active::before {
    content: "";
    position: absolute;
    width: 400px;
    height: 750px;
    top: -39px;
    left: -39px;
    background-image: url(../../images/landing/bg/iphone-slider.png);
    z-index: 99;
  }

  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: none;
  }

  .swiper-pagination-bullet {
    width: 36px;
    height: 5px;
    border-radius: 3px;
  }

  .swiper-pagination-bullet-active {
    background: #ff3494;
  }

  /* 8. newslater-area */
  .newslater-area h2,
  .newslater-area p {
    color: #fff;
  }

  .newslater-area p {
    opacity: 0.8;
  }

  .newslater input {
    background: none !important;
    border-radius: 30px;
    border: 1px solid #fff !important;
    box-shadow: none !important;
    padding: 9px 30px;
    color: #fff !important;
    height: calc(1.5em + .75rem + 10px);
  }

  .newslater .form-group {
    margin-bottom: 0;
  }

  .newslater button {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    color: #000;
  }

  .newslater button:hover {
    background: #ff3494;
    color: #fff;
  }

  .newslater *::-moz-placeholder {
    color: #fff;
    font-size: 14px;
    opacity: 0.8;
    opacity: 1;
  }

  .newslater *::placeholder {
    color: #fff;
    opacity: 0.8;
    font-size: 14px;
    opacity: 1;
  }

  .slick-slide {
    outline: none;
  }

  .owl-prev,
  .owl-next {
    color: #ff3494 !important;
    font-size: 24px !important;
    background: transparent !important;
  }

  .owl-next::before {
    content: "\6d";
    font-family: "dripicons-v2" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .owl-prev::before {
    content: "\6c";
    font-family: "dripicons-v2" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}


@import "./animate.scss";
@import "./magnific-pop.scss";
@import "./slick.scss";
@import "./_utils.scss";


@import "./_media.scss";
