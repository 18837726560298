.fqa-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.5s ease;
  background: #fef9fc;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  & i{
    transition: all 0.5s ease;
  }
  &.active{
      background: $primary-500;
      color: white;
      & i{
        transform: rotate(180deg);
      }

  }
}
